import { IosShareRounded, ManageSearchRounded } from "@mui/icons-material";
import BookmarkButton from "./BookmarkButton";

const TrackActions = ({
    authenticated,
    displayWPM,
    onShareClick,
    onSpeedClick,
    setOpenSearchTranscript,
    showLabels,
    track,
}) => {
    const shareIconStyle = {
        fontSize: '20px',
        marginTop: '8px',
    }

    const bookmarkIconStyle = {
        fontSize: '22px',
        marginTop: '8px',
    }

    const searchIconStyle = {
        fontSize: '26px',
        marginTop: '6px',
    }

    return !track ? <></>
        : <div>
            <table style={{ width: '100%', textAlign: 'center' }}>
                <tbody>
                    <tr>
                        {!authenticated ? <></> :
                            <td style={{ width: '25%' }}>
                                <BookmarkButton
                                    trackId={track.fullTrackId ?? track.id}
                                    buttonClassName='trackOverviewActionButton'
                                    iconClassName={showLabels ? 'trackOverviewActionIcon' : ''}
                                    iconStyle={bookmarkIconStyle} />
                            </td>
                        }

                        <td style={{ width: '25%' }}>
                            <button onClick={onSpeedClick} className='trackOverviewActionButton'>
                                <div className='trackOverviewDisplayWPM'>
                                    {displayWPM}
                                </div>
                                <div style={{ display: showLabels ? 'inline' : 'none' }}>
                                    Speed
                                </div>
                            </button>
                        </td>

                        <td style={{ width: '25%' }}>
                            <button onClick={onShareClick} className='trackOverviewActionButton'>
                                <div className='trackOverviewActionIcon'>
                                    <IosShareRounded style={shareIconStyle} />
                                </div>
                                <span style={{ display: showLabels ? 'inline' : 'none' }}>
                                    Share
                                </span>
                            </button>
                        </td>

                        <td style={{ width: '25%' }}>
                            <button onClick={() => { setOpenSearchTranscript(true); }} className='trackOverviewActionButton'>
                                <div className='trackOverviewActionIcon'>
                                    <ManageSearchRounded style={searchIconStyle} />
                                </div>
                                <span style={{ display: showLabels ? 'inline' : 'none' }}>
                                    Search
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
};

export default TrackActions;

