import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import { loadLatestSubscribed } from '../redux/actions/userActions';

// Router.
import { Navigate } from 'react-router-dom';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import { loadTrack } from '../components/audioHelper';
import { isTooOld } from '../components/timeHelper';
import AudioPlayerDrawer from '../components/AudioPlayerDrawer';
import Loading from '../components/Loading';
import BottomHomeMenu from '../components/BottomHomeMenu';

import TrackInfoRow from '../components/TrackInfoRow';
import { Refresh } from '@material-ui/icons';


const Subscriptions = ({
    authenticated,
    latestSubscribedData,
    latestSubscribedLoading,
    loadLatestSubscribed,
}) => {
    const analytics = getAnalytics();
    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);
    const trackIdRef = useRef(null)

    useEffect(() => {
        if (
            !latestSubscribedLoading &&
            (!latestSubscribedData || isTooOld(latestSubscribedData.setTime, 60))
        ) {
            loadLatestSubscribed();
        }

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'subscriptions'
        });
    }, []);

    const refresh = () => {
        loadLatestSubscribed();
    }

    const playTrack = (trackId, mp3URL, startTime) => () => {
        trackIdRef.current = trackId;
        loadTrack(mp3URL, startTime);
        setShowAudioPlayerDrawer(true);
    };

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
    }

    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <AudioPlayerDrawer
                        open={showAudioPlayerDrawer}
                        onClose={closeAudioPlayer}
                        trackId={trackIdRef.current} />
                <BottomHomeMenu />
                <div className='mainWithBottomMenu'>
                    {latestSubscribedLoading ? <Loading /> :
                        <div>
                            <div>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100%' }}>
                                                <div style={{ padding: '20px' }}>
                                                    <div className='heading'>
                                                        Subscriptions
                                                    </div>
                                                    <div className='subheading'>
                                                        Recent Episodes
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ minWidth: '150px', textAlign: 'right' }}>
                                                <button style={{ padding: '20px' }} onClick={refresh}>
                                                    <Refresh style={{ fontSize: '24px' }} />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='thinRightDivider' />
                            {!latestSubscribedData || latestSubscribedData.trackInfos.length === 0 ?
                                <div style={{ padding: '20px' }}>
                                    Subscribe to speakers and see their latest episodes here.
                                </div>
                                :
                                <div>
                                    <div>
                                        {latestSubscribedData.trackInfos.map((track, subscription_idx) => (
                                            <TrackInfoRow
                                                key={subscription_idx}
                                                trackInfo={track}
                                                playTrack={playTrack}
                                                hideBottomBorder={subscription_idx === latestSubscribedData.trackInfos.length - 1}
                                            />
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

Subscriptions.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

Subscriptions.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    latestSubscribedData: state.user.latestSubscribedData,
    latestSubscribedLoading: state.user.latestSubscribedLoading,
})

const mapActionsToProps = {
    loadLatestSubscribed,
}

export default connect(mapStateToProps, mapActionsToProps)(Subscriptions);