
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useEffect, useState } from 'react';
import { fancyTimeFormatWithHMS } from './timeHelper';


const StartSecondsDrawer = ({
    close,
    open,
    track,
}) => {

    const [startSeconds, setStartSeconds] = useState(0);
    const audio = document.getElementById('audio');

    useEffect(() => {
        if (!track) {
            return;
        }
        if (audio.src !== track.mp3URL) {
            audio.src = track.mp3URL;
        }
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let start = 0;
        let startParam = urlParams.get('start');
        if (startParam) {
            startParam = startParam.replace('s', '');
            const startNumber = Number(startParam);
            if (startNumber) {
                start = startNumber
            }
        }
        if (start > 0) {
            audio.pause();
            setTimeout(() => {
                setStartSeconds(start)
            }, 500);
        } else {
            audio.play();
            close();
        }
    }, [track])

    const playFrom = (startSeconds) => () => {
        audio.currentTime = startSeconds;
        audio.play();
        close();
    }

    return (
        <div>
            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '20px 20px 0px 0px'
                    }
                }}
                anchor='bottom' open={open} onClose={close}>
                <Box
                    sx={{ width: 'auto' }}
                    role="presentation"
                >
                    <div className='horizontalCenter'>
                        {startSeconds === 0 ? <></> :
                            <div style={{ padding: '40px 20px', textAlign: 'center' }}>
                                <div className='subheading'>found start in link</div>
                                <div className='heading'>start playing from</div>
                                <table style={{ width: '100%', marginTop: '15px' }}>
                                    <tbody>
                                        <tr style={{ width: '100%' }}>
                                            <td style={{ width: '50%', paddingRight: '6px'}}>
                                                <button className='startSecondsButton' onClick={playFrom(startSeconds)} style={{ borderColor: 'rgb(30, 30, 30)' }}>
                                                    {fancyTimeFormatWithHMS(startSeconds)}
                                                </button>
                                            </td>
                                            <td style={{ width: '50%', paddingLeft: '6px'}}>
                                                <button className='startSecondsButton' onClick={playFrom(0)} style={{ borderColor: 'rgb(224, 224, 224)' }}>
                                                    beginning
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(StartSecondsDrawer);
