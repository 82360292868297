export const RAINBOW = [
    // orange
    "#ffd7ba",
    // pink blush / peach
    "#faddea",
    // purple
    "#dec8e5",
    // blue
    "#d7e3fc",
    // aqua
    "#bce4e6",
    // green
    "#badfc9",
    // olive
    "#dbe1bc",
    // yellow
    "#ffeecc",
];

export const ACCENT_COLOR = '#5468ff';

export const randomColor = () => {
    return `${RAINBOW[Math.floor(Math.random() * RAINBOW.length)]}80`;
}

export const randomColorExcept = (exclude) => {
    const excludeSet = new Set(exclude);
    const include = [];
    for (let i = 0; i < RAINBOW.length; i++) {
        const color = RAINBOW[i];
        if (!excludeSet.has(color)) {
            include.push(color);
        }
    }
    return include[Math.floor(Math.random() * include.length)];
}

export const getColorForNum = (num) => {
    return RAINBOW[num % RAINBOW.length];
}

export const speakerColor = (speakerId, speakersData) => {
    if (!speakersData) {
        return RAINBOW[RAINBOW.length - 1];
    }
    const sumNameLenghths = speakersData.reduce((partialSum, a) => partialSum + a.name.length, 0);
    for (let i = 0; i < speakersData.length; i++) {
        if (speakersData[i].id === speakerId) {
            return RAINBOW[(i + sumNameLenghths) % RAINBOW.length];
        }
    }
    return RAINBOW[RAINBOW.length - 1];
}