import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

// Relative time.
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { LABEL_APPNAME, LABEL_FOR_ME, LABEL_TAGLINE } from '../constants/labels';

// Redux.
import { connect } from 'react-redux';

// MUI.
import { Settings } from '@material-ui/icons';


// Components.
import NextUpEpisodes from './NextUpEpisodes';
import ForMePreview from './ForMePreview';

import { TIP_SETTINGS } from '../constants/tips';
import TestFlight from './TestFlight';
import SearchBoxWithSuggestions from './SearchBoxWithSuggestions';
import ExampleEmojiClips from './ExampleEmojiClips';
import PodcastAlbumSlider from './PodcastAlbumSlider';


const HomeContent = ({
    // Redux.
    authenticated,
}) => {
    const analytics = getAnalytics();

    const navigate = useNavigate();

    useEffect(() => {
        dayjs.extend(relativeTime);
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'home'
        });
    }, []);

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    const querySelected = (query) => {
        window.location.href = `/search/${encodeURIComponent(query)}`;
    }

    const loginClick = () => {
        logEvent(analytics, 'login_click');
        navigate('/login');
    }

    return (
        <div id="homeContent" className='container hideScrollbar'>
            <div>
                {!authenticated ?
                    <div>
                        <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                            {/* <div style={{ margin: '20px', textAlign: 'right' }}>
                                <button onClick={searchClick} style={{ textAlign: 'right' }}>
                                    <SvgIcon sx={{ stroke: "#ffffff", strokeWidth: 0.6 }} >
                                        <Search style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                                    </SvgIcon>
                                </button>
                            </div> */}

                            <div style={{ padding: '30px 0px 30px' }}>
                                <div style={{ padding: '0px 0px 30px', overflow: 'hidden' }}>
                                    <PodcastAlbumSlider />
                                </div>
                                <div style={{ lineHeight: '1.0' }}>
                                    <img className='appName' src='/appname2.png' />
                                </div>
                                <div style={{ textAlign: 'center', padding: '30px 0px 15px' }}>
                                    <TestFlight />
                                </div>
                                <div>
                                    <button className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Log in or sign up
                                        </span>
                                        {/* <ChevronRightRounded style={{ fontSize: '22px', verticalAlign: 'bottom' }} /> */}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '30px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0px auto', maxWidth: '340px' }}>
                                    <div className='homePreview'>
                                        <div style={{ fontWeight: '600', lineHeight: 1.3}}>
                                            Find Your Next Favorite Podcast
                                        </div>
                                        <div>
                                            <div style={{ paddingTop: '30px' }}>
                                            With over 3 million shows available, finding new podcasts can be overwhelming.
                                            </div>
                                            <div style={{ paddingTop: '30px' }}>
                                            Podeux makes it easy with previews that match your interests.
                                            </div>
                                            <div style={{ paddingTop: '30px' }}>
                                            Speakers and topics are highlighted, so you can quickly find content that resonates.
                                            </div>
                                            <div style={{ paddingTop: '30px' }}>
                                            The podcast app for podcast lovers is here.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '30px 0px', textAlign: 'center', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div>
                                <div>
                                    <div className='homePreviewSubtitle'>
                                        Tap to preview.
                                    </div>
                                </div>
                                <div>
                                    <ExampleEmojiClips />
                                </div>

                            </div>
                        </div>

                        {/* <div style={{ padding: '40px 0px 40px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'center', padding: '0px 0px 20px' }}>
                                    <TestFlight />
                                </div>
                                <div>
                                    <button className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Log in or sign up
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div> */}

                        <div style={{ background: 'rgb(250, 250, 250)', padding: '16px', textAlign: 'center' }}>
                            <span style={{ width: '50%', textAlign: 'right', fontSize: '13px', color: 'rgb(113, 113, 113)' }}>
                                © 2024 {LABEL_APPNAME}
                            </span>
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{ padding: '20px' }}>
                            <SearchBoxWithSuggestions querySelected={querySelected} initialQuery={''} />
                        </div>
                        <div className='thinDivider' />
                        {NoQueryView(navigateToUrl)}
                    </div>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(HomeContent);


const NoQueryView = (navigateToUrl) => {
    const forMeClick = () => {
        // TODO use navigate?
        window.location = '/clips';
    }

    const settingsClick = () => {
        localStorage.setItem(TIP_SETTINGS, true);
        navigateToUrl('/settings')();
    }

    return (
        <div style={{ fontSize: '16px', padding: '10px 0px' }}>
            <div>
                <div>
                    <div style={{ padding: '10px 20px' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <button onClick={forMeClick} className='heading'>
                                            <span style={{ verticalAlign: 'middle' }}>
                                                {LABEL_FOR_ME}
                                            </span>
                                        </button>
                                    </td>
                                    <td>
                                        <button onClick={settingsClick} className={localStorage.TIP_SETTINGS ? '' : 'tipSettings'} style={{ width: '36px', height: '36px' }}>
                                            <div className={localStorage.TIP_SETTINGS ? '' : 'pulse'}>
                                                <Settings style={{ fontSize: '27px', verticalAlign: 'middle' }} />
                                            </div>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <ForMePreview />
                    </div>
                    <div className='thinDivider' />
                    <div style={{ textAlign: 'center', padding: '24px', fontWeight: '500' }}>
                        <TestFlight />
                    </div>
                    <div className='thinDivider' />
                    <NextUpEpisodes navigateToUrl={navigateToUrl} />
                </div>
            </div>
        </div>
    );
}

