

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import CloseFooter from './CloseFooter';
import EntityInfo from './EntityInfo';

import useWindowDimensions from './windowHelper';

import Drawer from '@mui/material/Drawer';

const EntityInfoDrawer = ({ speakerId, open, onClose }) => {
    const { state } = useLocation();
    const { height } = useWindowDimensions();

    useEffect(() => {
        if (state && !!state.closeAllModal) {
            onClose();
        }
    }, [state])

    return (
        <Drawer
            PaperProps={{
                sx: {
                    borderRadius: '20px 20px 0px 0px'
                }
            }}
            anchor='bottom' open={open} onClose={onClose}>
            <div className='horizontalCenter' style={{ height: height - 30, background: 'white' }}>
                <div className='hasCloseFooter'>
                    <EntityInfo speakerId={speakerId} />
                    <CloseFooter onClick={onClose} />
                </div>
            </div>
        </Drawer>
    );
};

export default EntityInfoDrawer;