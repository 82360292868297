
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'

import {
    setCurrentClipsTopic,
    setNextClipsInfo,
} from "../redux/actions/dataActions";

import { shuffle } from 'lodash';

import { loadTrack } from './audioHelper';
import { eventLog } from './eventHelper';

import { LABEL_APPNAME } from '../constants/labels';

// Algolia.
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from '../constants/algolia_constants';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';
import {
    useHits,
    useSearchBox,
} from 'react-instantsearch-core';

import {
    Configure,
    Hits,
    Highlight,
    Index,
    InstantSearch
} from 'react-instantsearch';


// Components.
import EntityInfoDrawer from './EntityInfoDrawer';
import SpeakerHeader from './SpeakerHeader';
import SpeakerTrack from './SpeakerTrack';
import TopicSpeakerClips from './TopicSpeakerClips';

import { SearchRounded } from '@material-ui/icons';

import Highlighter from 'react-highlight-words';
import useWindowDimensions from './windowHelper';
import Loading from './Loading';
import SearchBoxWithSuggestions from './SearchBoxWithSuggestions';
import BottomHomeMenu from './BottomHomeMenu';


const ClipsResultsLabel = ({ query, hits }) => {
    return (
        <div style={{ padding: '30px 20px 10px' }}>
            {
                hits.length === 0 ?
                    <div className='heading'>
                        No previews found for "{query}"
                    </div>
                    :
                    <div className='subheading' style={{ fontSize: '14px' }}>
                        Previews for "{query}"
                    </div>
            }
        </div>
    )
}

const AlgoliaSearch = ({
    initialQuery,
    noQueryView,

    // Redux.
    authenticated,
    setCurrentClipsTopic,
    setNextClipsInfo,
}) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    const relatedTopicClipsClick = (relatedTopicClips) => () => {
        loadTrack(relatedTopicClips.clips[0].mp3URL, 0);
        setCurrentClipsTopic(relatedTopicClips.id);
        setNextClipsInfo(relatedTopicClips.id, relatedTopicClips.clips);
        navigate('/clips', { state: { closeAllModal: true } });
    };

    const playSearchResult = (query, hit, hits) => {
        let clips = shuffle(hits.filter(clip => clip.id !== hit.id));
        clips.unshift(hit);
        loadTrack(hit.mp3URL, 0);
        relatedTopicClipsClick({
            'id': LABEL_APPNAME,
            'clips': clips,
        })();
        eventLog(5, {
            'clipId': hit.id,
            'speakerId': hit.person.id,
            'topicIds': hit.topics,
            'query': query,
        })
        navigate('/clips');
    };

    const playSuggestion = (suggestion, clipId) => () => {
        suggestion.clips = shuffle(suggestion.clips);

        if (clipId) {
            const newClips = [];
            for (let i = 0; i < suggestion.clips.length; i++) {
                const clip = suggestion.clips[i];
                if (clip.id === clipId) {
                    newClips.push(clip);
                    for (let j = 0; j < suggestion.clips.length; j++) {
                        if (j !== i) {
                            newClips.push(suggestion.clips[j])
                        }
                    }
                    break;
                }
            }
            suggestion.clips = newClips;
        }

        loadTrack(suggestion.clips[0].mp3URL, 0)
        relatedTopicClipsClick(suggestion)();
        navigate('/clips');
    }

    const SearchTopicSuggestions = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <TopicSuggestionHits query={query} refine={refine} playSuggestion={props.playSuggestion} width={props.width} />
            </Index>
        );
    }

    const MaybeShowPerson = ({ query, hits, authenticated }) => {

        const [person, setPerson] = useState(null);
        const [speakerTracks, setSpeakerTracks] = useState([]);

        const [openPersonInfo, setOpenPersonInfo] = useState(false);

        const onSpeakerClick = () => {
            setOpenPersonInfo(true);
        };

        const togglePersonInfo = (open) => (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            if (!!person && !!person.id) {
                setOpenPersonInfo(open);
            }
        }

        useEffect(() => {
            setPerson(null);
            if (hits && query) {
                for (let i = 0; i < hits.length; i++) {
                    const hitPerson = hits[i].speaker;
                    if (query.length * 4 >= hitPerson.name.length) {
                        if (hitPerson.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
                            setPerson(hitPerson);
                            setSpeakerTracks(hits[i].tracks);
                        }
                        break;
                    }
                }
            }
        }, [query, hits]);

        return (
            !person ? <div className='thinRightDivider'></div> :
                <div style={{ padding: '16px 0px' }}>
                    <EntityInfoDrawer
                        open={openPersonInfo}
                        onClose={togglePersonInfo(false)}
                        speakerId={person.id} />
                    <SpeakerHeader speaker={person} onSpeakerClick={onSpeakerClick} />

                    <div>
                        <div className='subheading' style={{ fontSize: '14px', padding: '20px 20px 0px' }}>
                            Trending episodes with {person.name}
                        </div>
                        {
                            speakerTracks.map((track, index) => (
                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(234, 234, 234)' }}>
                                    <SpeakerTrack speakerId={person.id} track={track} authenticated={authenticated} />
                                </div>
                            ))
                        }
                    </div>

                </div>
        )
    };

    const ClipHits = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        const { hits, results, sendEvent } = useHits(props);
        const {
            playSearchResult,
        } = props;

        useEffect(() => {
            document.getElementById('homeContent').scrollTo(0, 0)
            document.getElementById('audio').pause();
        }, [query]);

        const Hit = ({ hit }) => {
            return (
                <div>
                    <table style={{ textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        playSearchResult(query, hit, hits)
                                    }}>
                                        <img alt='profile' src={hit.person.imageURL} style={{ marginRight: '20px', width: '78px', height: '78px', border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                                    </button>
                                </td>
                                <td>
                                    <div>
                                        <button className='clipSearchResultTitle'
                                            onClick={() => {
                                                playSearchResult(query, hit, hits)
                                            }}>
                                            <Highlight hit={hit} attribute="title" />
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={() => {
                                            playSearchResult(query, hit, hits)
                                        }} style={{ marginTop: '6px', textAlign: 'left' }}>
                                            <div className='speakerNameSmall'>
                                                <Highlight hit={hit} attribute="person.name" />
                                            </div>
                                            <div className='speakerDescription'>
                                                <Highlight hit={hit} attribute="person.description" />
                                            </div>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className='searchResults'>
                <ClipsResultsLabel query={query} hits={hits} />
                <Hits hitComponent={Hit} />
            </div >
        )
    }

    const SearchTopics = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="topics">
                <Configure hitsPerPage={1} />
                <TopicHits query={query} refine={refine} playSuggestion={props.playSuggestion} width={props.width} />
            </Index>
        );
    }

    const TopicHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        const isExactMatch = (hit) => {
            return hit.name === props.query.toLowerCase();
        }

        const hasMultipleSpeakers = (hit) => {
            let speakersIds = new Set();
            for (let i = 0; i < hit.clips.length; i++) {
                const clip = hit.clips[i];
                speakersIds.add(clip.person.id);
            }
            return speakersIds.size > 1;
        }

        const TopicHit = ({ hit }) => {

            const searchHit = () => {
                props.refine(hit.name);
            }

            return (
                isExactMatch(hit) && hasMultipleSpeakers(hit) ?
                    <div style={{ padding: '10px 16px 10px 0px', marginLeft: '20px' }}>
                        <div style={{ padding: '0px 4px' }}>
                            <TopicSpeakerClips
                                playTopic={props.playSuggestion}
                                topic={{ id: hit.name, clips: hit.clips }}
                                width={Math.min(538, props.width) - 44}
                            />
                        </div>

                    </div>
                    :
                    <div></div>
            )
        }

        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <div className='searchResults'>
                    {hits.map((hit, i) => (
                        <TopicHit key={i} hit={hit} />
                    ))}
                </div>
            </Index>
        );
    }

    const TopicSuggestionHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        const isExactMatch = (hit) => {
            return hit.name === props.query.toLowerCase();
        }

        const SuggestionHit = ({ hit, isLast }) => {

            const searchHit = () => {
                props.refine(hit.name);
            }

            return (
                isExactMatch(hit) ?
                    <div></div>
                    :
                    <table style={{ padding: '10px 16px 10px 14px', marginLeft: '20px', borderBottom: isLast ? '' : '0.5px solid rgb(224, 224, 224)' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <button onClick={searchHit}>
                                        <SearchRounded style={{ color: 'rgb(120, 120, 120)', marginRight: '6px', fontSize: '21px', verticalAlign: 'middle' }} />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ textAlign: 'left', width: '100%' }} onClick={searchHit}>
                                        <div style={{ fontSize: '16px' }}>
                                            <Highlighter
                                                highlightClassName="ais-Highlight-highlighted"
                                                unhighlightClassName="ais-Highlight-nonHighlighted"
                                                searchWords={[props.query]}
                                                autoEscape={true}
                                                textToHighlight={hit.name}
                                            />
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            )
        }

        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <div className='searchResults'>
                    {hits.map((hit, i) => (
                        <SuggestionHit key={i} hit={hit} isLast={i === hits.length - 1} />
                    ))}
                </div>
            </Index>
        );
    }

    const SearchSpeakers = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <SpeakerHits query={query} authenticated={props.authenticated} />
            </Index>
        );
    }

    const SpeakerHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <MaybeShowPerson query={props.query} hits={hits} authenticated={props.authenticated} />
            </Index>
        )
    }

    return (
        <div className='mainWithBottomMenu'>
            {!initialQuery ? noQueryView :
                <InstantSearch
                    indexName="clips"
                    initialUiState={{
                        "clips": {
                            query: initialQuery ?? '',
                        }
                    }}
                    searchClient={searchClient}>
                    {/* <div>
                    <SearchBoxWithSuggestions fixToTop={true} querySelected={querySelected} initialQuery={initialQuery ?? ''} />
                </div> */}
                    <div style={{ padding: '60px 0px 20px 0px' }}>
                        <SearchTopicSuggestions navigateToUrl={navigateToUrl} width={width} playSuggestion={playSuggestion} />
                        <SearchSpeakers authenticated={authenticated} />
                        <SearchTopics navigateToUrl={navigateToUrl} width={width} playSuggestion={playSuggestion} />
                        <Configure hitsPerPage={10} />
                        <ClipHits
                            playSearchResult={playSearchResult}
                        />
                    </div>
                </InstantSearch>
            }
            <BottomHomeMenu />
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
    setCurrentClipsTopic,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(AlgoliaSearch);