import axios from 'axios';

import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import { logoutUser, updateForMe } from '../redux/actions/userActions';

// Router.
import { Navigate, useNavigate } from 'react-router-dom';

// Firebase.
import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/compat/auth';

// Components.
import BottomHomeMenu from '../components/BottomHomeMenu';

import { clearAllTipsOnLogout } from '../constants/tips';
import { AddCircle, ChevronRight, RemoveCircle } from '@material-ui/icons';
import SearchTopicsDrawer from '../components/SearchTopicsDrawer';

import { validTokenExists } from '../firebaseHelper';
import { LABEL_FOR_ME } from '../constants/labels';
import { setCurrentClipsTopic, setNextClipsInfo } from '../redux/actions/dataActions';
import { loadTrack } from '../components/audioHelper';
import { ACCENT_COLOR } from '../constants/colors';


const Settings = ({
    authenticated,
    forMeData,
    phoneNumber,
    logoutUser,
    updateForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
}) => {

    const maxTopics = 10

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'settings'
        });

        validTokenExists()
            .then(() => {
                setLoadingTopUserTopics(true);
                axios
                    .post('/top_user_topics', {})
                    .then((res) => {
                        setTopics(res.data.topics.slice(0, maxTopics));
                        setLoadingTopUserTopics(false);
                    })
                    .catch((err) => {
                        // TODO
                        console.log(err);
                        setLoadingTopUserTopics(false);
                    });
            })
            .catch((e) => {
                logoutUser();
            });
    }, []);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (!!user) {
                // Expected to be signed in.
            } else {
                logoutUser();
                const analytics = getAnalytics();
                logEvent(analytics, 'logout');
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [logoutUser]);

    const logoutClick = () => {
        clearAllTipsOnLogout();

        firebase.auth().signOut().then(() => {

        }).catch((error) => {
            // An error happened.
        });
    }

    const [showSearchTopic, setShowSearchTopic] = useState(false);

    const [topics, setTopics] = useState([]);

    const [loadingTopUserTopics, setLoadingTopUserTopics] = useState(false);
    const [alreadyExistsError, setAlreadyExistsError] = useState(null);

    const [updatingClipsWithTopic, setUpdatingClipsWithTopic] = useState(null);
    const [updatingClipsWithTopicLoading, setUpdatingClipsWithTopicLoading] = useState(false);

    const addTopicClick = () => {
        setShowSearchTopic(true)
    }

    const selectedSearchTopic = (topic) => () => {
        let alreadyExists = topics.indexOf(topic) !== -1

        if (alreadyExists) {
            setAlreadyExistsError(topic);
        } else {
            setAlreadyExistsError(null);
            validTokenExists()
                .then(() => {
                    setUpdatingClipsWithTopic(topic);
                    setUpdatingClipsWithTopicLoading(true);
                    const reqBody = {
                        'addTopic': topic,
                    };
                    axios
                        .post('/set_user_topic', reqBody)
                        .then((res) => {
                            setTopics([topic].concat(topics))
                            setUpdatingClipsWithTopicLoading(false);
                            updateForMe(res.data);
                        })
                        .catch((err) => {
                            // TODO
                            setUpdatingClipsWithTopicLoading(false);
                            console.log(err);
                        });
                });
        }
        setShowSearchTopic(false);
    }

    const deleteTopic = (topic) => () => {
        var newTopics = topics.filter(function (element) {
            return element !== topic;
        });
        setTopics(newTopics)
        validTokenExists()
            .then(() => {
                const reqBody = {
                    'deleteTopic': topic,
                };
                axios
                    .post('/set_user_topic', reqBody)
                    .then((res) => {
                        updateForMe(res.data);
                    })
                    .catch((err) => {
                        // TODO
                        console.log(err);
                    });
            });
    };

    const DeleteButtonContent = (topic) => (
        <div>
            <RemoveCircle style={{ verticalAlign: 'middle', color: 'red', marginRight: '16px' }} />
            <span style={{ verticalAlign: 'middle' }} >{topic}</span>
        </div>
    );

    const navigate = useNavigate();

    const viewClipsForMe = () => {
        loadTrack(forMeData.clips[0].mp3URL, 0);
        setCurrentClipsTopic(LABEL_FOR_ME);
        setNextClipsInfo(LABEL_FOR_ME, forMeData.clips);
        navigate('/clips')
    };

    const isWebApp = () => {
        const isInWebAppiOS = (window.navigator.standalone === true);
        const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
        return isInWebAppiOS || isInWebAppChrome;
    }
    const showInstall = !isWebApp();

    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <SearchTopicsDrawer selectedSearchTopic={selectedSearchTopic} open={showSearchTopic} onClose={() => { setShowSearchTopic(false) }} />
                <BottomHomeMenu />
                <div className='mainWithBottomMenu'>
                    <table style={{ padding: '20px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <div style={{ fontSize: '15px' }}>
                                        {phoneNumber}
                                    </div>
                                </td>
                                <td style={{ minWidth: '120px', textAlign: 'right' }}>
                                    <button onClick={logoutClick} style={{ color: 'rgb(120, 120, 120)', textAlign: 'left', fontSize: '15px' }}>
                                        Log out
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='thinRightDivider' />
                    <div style={{ padding: '20px' }}>

                        <div style={{ paddingBottom: '10px' }} className='heading'>
                            Show me podcasts on...
                        </div>
                        {loadingTopUserTopics ?
                            <div className='glow'>
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                            </div>
                            :
                            <div>
                                {alreadyExistsError ?
                                    <div style={{ padding: '16px 0px', fontWeight: '600', fontSize: '15px' }}>
                                        ⚠️ "{alreadyExistsError}" already added.
                                    </div>
                                    :
                                    updatingClipsWithTopic ?
                                        <div style={{ padding: '16px 20px', margin: '10px 0px', borderRadius: '8px', background: 'rgb(242, 242, 242)', fontWeight: '600', fontSize: '15px' }}>
                                            {updatingClipsWithTopicLoading ?
                                                <span className='glow'>
                                                    Adding previews for "{updatingClipsWithTopic}"
                                                </span>
                                                :
                                                <div>
                                                    Finished adding previews for "{updatingClipsWithTopic}"
                                                    <div>
                                                        <button onClick={viewClipsForMe} style={{ paddingTop: '10px' }}>
                                                            <span style={{ verticalAlign: 'middle', fontSize: '15px' }}>Go to previews for me</span>
                                                            <ChevronRight style={{ verticalAlign: 'middle', fontSize: '23px', color: 'rgb(120, 120, 120)' }} />
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                                <button className='settingForMeTopic' onClick={addTopicClick}>
                                    <div>
                                        <AddCircle style={{ verticalAlign: 'middle', color: ACCENT_COLOR, marginRight: '16px' }} />
                                        <span style={{ verticalAlign: 'middle' }} >Add Topic</span>
                                    </div>
                                </button>
                                {topics.map((topic, i) => (
                                    <button key={i} className='settingForMeTopic' onClick={deleteTopic(topic)}>
                                        {DeleteButtonContent(topic)}
                                    </button>
                                ))}

                                <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                                    <div style={{ color: 'rgb(80, 80, 80)', fontSize: '13px' }}>
                                        Topics updated based on activity.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

Settings.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    phoneNumber: state.user.phoneNumber,
    forMeData: state.user.forMeData,
})

const mapActionsToProps = {
    logoutUser,
    updateForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(Settings);