import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from "react-redux";
import {
    setCurrentClipsTopic,
    setNextClipsInfo,
} from '../redux/actions/dataActions';

// Components.
import { loadTrack } from './audioHelper';
import { LABEL_FOR_ME } from '../constants/labels';

import { getAnalytics, logEvent } from 'firebase/analytics';
import useWindowDimensions from './windowHelper';



const ExampleEmojiClips = ({
    setCurrentClipsTopic,
    setNextClipsInfo,
}) => {
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const { width } = useWindowDimensions();

    useEffect(() => {
        setLoading(true);
        axios
            .get('/first_user_emoji_clips')
            .then((res) => {
                setLoading(false);
                setData(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const emojiClick = (emoji) => () => {
        logEvent(analytics, 'example_emoji_click');
        let emojiClips = data.emojiToClips[emoji];
        playClips(emojiClips);
        // let otherClips = [];
        // for (let i = 0; i < emojis.length; i++) {
        //     if (emoji !== emojis[i]) {
        //         otherClips = otherClips.concat(data.emojiToClips[emojis[i]])
        //     }
        // }
        // const clips = emojiClips.concat(shuffle(otherClips)).slice(0, 30);
        // playClips(clips);
    };

    const playClips = (clips) => {
        loadTrack(clips[0].mp3URL, 0);
        setCurrentClipsTopic(LABEL_FOR_ME);
        setNextClipsInfo(LABEL_FOR_ME, clips);
        navigate('/clips');
    };

    const emojisPerRow = 4;
    const emojiSize = '44px';

    return (
        <div>
            {loading || !data ?
                <div className='pulse' style={{ color: 'rgb(120, 120, 120)', fontSize: '16px', padding: '40px' }}>
                    Loading preview...
                </div>
                :
                <div  style={{ margin: '20px auto 10px', maxWidth: '360px' }}>
                    {data.emojiOrder.map((emoji, i) => (
                        <button className='animate__animated animate__pulse animate__infinite' key={i} onClick={emojiClick(emoji)} style={{ cursor: 'pointer', padding: '10px 0px', width: `${Math.min(width, 360) / emojisPerRow}px` }}>
                            <div style={{ fontSize: emojiSize }}>
                                {emoji}
                            </div>
                        </button>
                    ))}
                </div>
            }
        </div>
    );
};


const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
    setCurrentClipsTopic,
    setNextClipsInfo,
};

export default connect(mapStateToProps, mapActionsToProps)(ExampleEmojiClips);