// Redux.
import { connect } from 'react-redux';

// React state and router.
import { useEffect, useState } from 'react'

// Components.
import { getAnalytics, logEvent } from "firebase/analytics";

// Relative time.
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { IosShareRounded } from '@mui/icons-material';
import { isAndroid } from '../components/deviceHelper';
import { MoreVert } from '@material-ui/icons';
import BottomHomeMenu from '../components/BottomHomeMenu';
import { LABEL_APPNAME, LABEL_TAGLINE } from '../constants/labels';


const Down = ({ }) => {
    return (
        <div>
            <div className='center'>
                <div style={{ textAlign: 'left', paddingBottom: '40px' }}>
                    <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                        <a href='/'>
                            <div>
                                <img className='appName' src='/appname2.png' />
                            </div>
                            <div className='tagline'>
                                {LABEL_TAGLINE}
                            </div>
                        </a>
                    </div>

                    <div style={{ minWidth: '300px', fontSize: '18px', paddingBottom: '10px' }}>
                        <div>
                            Temporarily down for upgrade.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Down);