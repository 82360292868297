import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';

import { BookmarkBorder, Close } from "@material-ui/icons";

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";
import { auth, loginPopupUI } from '../firebaseHelper'

const LoginButtonPopup = ({
    authenticated,
    close,
    loginUser,
}) => {

    const navigate = useNavigate();

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'login_button_popup'
        });

        const audio = document.getElementById('audio');
        audio.pause();

        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if (!!user) {
                user.getIdToken().then(function (idToken) {
                    loginUser(idToken, user.refreshToken, {});
                    logEvent(analytics, 'login_button_popup:success');
                    close();
                });
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const navToLogin = () => {
        if (authenticated) {
            close();
        } else {
            navigate('/login');
        }
    }

    return (
        <div className='center'>
            <div style={{ background: 'white', borderRadius: '20px', padding: '36px 0px', width: '350px' }}>
                <div style={{ paddingLeft: '20px', textAlign: 'left', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(248, 248, 248)' }}>
                    <button style={{ fontSize: '18px', fontWeight: '600' }} onClick={navToLogin}>
                        {authenticated ? 'LOG IN SUCCESSFUL' : 'Log in or create an account.'}
                    </button>
                    {authenticated ? <></> :
                        <div style={{ fontSize: '14px', color: 'rgb(80, 80, 80)' }}>
                            We'll send a text to verify your phone.
                        </div>
                    }
                </div>

                {authenticated ? <></> :
                    <div style={{ textAlign: 'center' }}>
                        { loginPopupUI }
                    </div>
                }
                <button onClick={close} className='popupClose'>
                    <Close />
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
    loginUser,
}

export default connect(mapStateToProps, mapActionsToProps)(LoginButtonPopup);
