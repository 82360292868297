
export const TIP_PLAY_FIRST_CLIP = 'TIP_PLAY_FIRST_CLIP';
export const TIP_SAVED_EPISODE = 'TIP_SAVED_EPISODE';
export const TIP_SECTION_NEXT = 'TIP_SECTION_NEXT';
export const TIP_SECTION_PREV = 'TIP_SECTION_PREV';
export const TIP_SETTINGS = 'TIP_SETTINGS';
export const TIP_SUBSCRIBED = 'TIP_SUBSCRIBED';

export const clearAllTipsOnLogout = () => {
    localStorage.clear();
}