import Drawer from '@mui/material/Drawer';
import CloseFooter from './CloseFooter';

// Algolia.
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';
import {
    useHits,
    useSearchBox,
    useInstantSearch,
} from 'react-instantsearch-core';
import {
    Configure,
    InstantSearch,
    SearchBox,
} from 'react-instantsearch';

import useWindowDimensions from './windowHelper';
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from '../constants/algolia_constants';
import ExampleTopics from './ExampleTopics';
import TopicButton from './TopicButton';


const TopicHits = (props) => {
    const { hits, results, sendEvent } = useHits(props);
    const { query, refine, clear, isSearchStalled } = useSearchBox(props);

    return (
        <div className='searchResults'>
            {hits.map((hit, i) => (
                <TopicButton key={i} query={query} name={hit.name} selectedSearchTopic={props.selectedSearchTopic} />
            ))}
        </div>
    );
};


const SearchTopicsDrawer = ({ open, onClose, selectedSearchTopic }) => {
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    const { height } = useWindowDimensions();

    function EmptyQueryBoundary({ children }) {
        const { indexUiState } = useInstantSearch();

        if (!indexUiState.query) {
            return (
                <ExampleTopics open={open} selectedSearchTopic={selectedSearchTopic} />
            );
        }
        return children;
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    borderRadius: '20px 20px 0px 0px'
                }
            }}
            anchor='bottom' open={open} onClose={onClose}>
            <div className='horizontalCenter hasCloseFooter' style={{ minHeight: `${height - 20}px` }}>
                <div style={{ padding: '20px 0px', borderRadius: '20px 20px 0px 0px' }}>
                    <InstantSearch
                        // routing={true}
                        indexName="topics"
                        searchClient={searchClient}>
                        <div style={{ padding: '0px 20px' }}>
                            <div className='algoliaCustom'>
                                <SearchBox
                                    placeholder="Search topics"
                                />
                            </div>
                        </div>

                        <div style={{ padding: '20px 0px 20px 0px' }}>
                            <EmptyQueryBoundary>
                                <Configure hitsPerPage={5} />
                                <TopicHits selectedSearchTopic={selectedSearchTopic} />
                            </EmptyQueryBoundary>
                        </div>
                    </InstantSearch>
                </div>
                <CloseFooter onClick={onClose} />
            </div>
        </Drawer>
    );
};

export default SearchTopicsDrawer;