// Redux.
import { connect } from "react-redux";
import {
    loadLatestSubscribed,
    loadRecentlyPlayed,
    loadRecentlySaved,
} from '../redux/actions/userActions';
import { useEffect, useRef, useState } from "react";
import { BookmarkRounded, ChevronRightRounded, History, SaveRounded } from "@material-ui/icons";
import { loadTrack } from "./audioHelper";
import AudioPlayerDrawer from "./AudioPlayerDrawer";
import TrackInfoRow from "./TrackInfoRow";


const NextUpEpisodes = ({
    navigateToUrl,

    // Redux.
    authenticated,
    loadLatestSubscribed,
    loadRecentlyPlayed,
    loadRecentlySaved,
    latestSubscribedData,
    recentlyPlayedData,
    recentlySavedData,
    savedTrackIds,
}) => {

    // TODO remove and handle server side.
    const [filteredLatestSubscribedTrackInfos, setFilteredLatestSubscribedTrackInfos] = useState([]);
    const [filteredRecentlySavedTrackInfos, setFilteredRecentlySavedTrackInfos] = useState([]);
    const [filteredRecentlyPlayedTrackInfos, setFilteredRecentlyPlayedTrackInfos] = useState([]);

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);
    const trackIdRef = useRef(null)

    const playTrack = (trackId, mp3URL, startSeconds) => () => {
        trackIdRef.current = trackId;
        loadTrack(mp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
    };
    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
    }

    useEffect(() => {
        // TODO figure out why this also gets called when the menu drawer is closed.
        if (authenticated) {
            loadRecentlySaved();
            loadRecentlyPlayed();
            loadLatestSubscribed();
        }
    }, []);

    useEffect(() => {
        if (!recentlySavedData || !recentlySavedData.trackInfos) {
            setFilteredRecentlySavedTrackInfos([]);
        } else {
            const filtered = [];
            for (let i = 0; i < recentlySavedData.trackInfos.length; i++) {
                const trackInfo = recentlySavedData.trackInfos[i];
                filtered.push(trackInfo);
            }
            setFilteredRecentlySavedTrackInfos(filtered.slice(0, 1));
        }
    }, [recentlySavedData])

    useEffect(() => {
        if (!recentlyPlayedData || !recentlyPlayedData.trackInfos) {
            setFilteredRecentlyPlayedTrackInfos([]);
        } else {
            const filtered = [];
            for (let i = 0; i < recentlyPlayedData.trackInfos.length; i++) {
                const trackInfo = recentlyPlayedData.trackInfos[i];
                filtered.push(trackInfo)
            }
            setFilteredRecentlyPlayedTrackInfos(filtered.slice(0, 1));
        }
    }, [recentlySavedData, recentlyPlayedData])

    useEffect(() => {
        if (!latestSubscribedData || !latestSubscribedData.trackInfos) {
            setFilteredLatestSubscribedTrackInfos([]);
        } else {
            const filtered = [];
            for (let i = 0; i < latestSubscribedData.trackInfos.length; i++) {
                const track = latestSubscribedData.trackInfos[i];
                filtered.push(track)
            }
            setFilteredLatestSubscribedTrackInfos(filtered.slice(0, 10));
        }
    }, [recentlySavedData, recentlyPlayedData, latestSubscribedData])

    const isTrackSaved = (trackId) => {
        const savedTrackIdSet = new Set(savedTrackIds);
        return savedTrackIdSet.has(trackId);
    };

    const allButton = (label, url, showBorder) => (
        <div style={{ textAlign: 'right', borderBottom: showBorder ? '1px solid rgb(224, 224, 224)' : '' }}>
            <button onClick={navigateToUrl(url)} style={{ padding: '10px 20px 20px' }}>
                <span style={{ fontSize: '16px', fontWeight: '600', verticalAlign: 'middle' }}>
                    {label}
                </span>
                <ChevronRightRounded style={{ color: 'rgb(120, 120, 120)', fontSize: '26px', verticalAlign: 'middle' }} />
            </button>
        </div>
    )

    const placeholder = (label, message, icon) => (
        <div style={{ margin: '20px 20px 0px', borderRadius: '8px', padding: '20px', background: 'rgb(242, 242, 242)' }}>
            <div className='heading'>
                {label}
            </div>
            <div style={{ fontSize: '15px', color: 'rgb(80, 80, 80)'}}>
                {message}
            </div>
            <div style={{ textAlign: 'center', padding: '40px 40px 40px'}}>
                {icon}
            </div>
        </div>
    )


    return (
        <div>

            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                trackId={trackIdRef.current} />
            <div>
                {filteredRecentlyPlayedTrackInfos.length === 0 ?
                    placeholder('Recently Played', 'Continue listening to episodes you play here.', <History style={{ fontSize: '50px'}} />) :
                    <div className='animate__animated animate__fadeIn'>
                        <div style={{ margin: '20px 20px 0px 20px' }}>
                            <button onClick={navigateToUrl('/recent')} className='heading'>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Recently Played
                                </span>
                                <ChevronRightRounded style={{ color: 'rgb(120, 120, 120)', fontSize: '30px', verticalAlign: 'middle' }} />
                            </button>
                        </div>
                        {filteredRecentlyPlayedTrackInfos.map((trackInfo, index) => (
                            <div key={index}>
                                <TrackInfoRow
                                    trackInfo={trackInfo}
                                    playTrack={playTrack}
                                    hideBottomBorder={index === filteredRecentlyPlayedTrackInfos.length - 1}
                                />
                            </div>
                        ))}
                        {allButton('More Recently Played', '/recent', filteredLatestSubscribedTrackInfos.length !== 0)}
                    </div>
                }

                {filteredRecentlySavedTrackInfos.length === 0 ?
                    placeholder('Saved', 'Saved episodes will show up here.', <BookmarkRounded style={{ fontSize: '50px'}} />) :
                    <div className='animate__animated animate__fadeIn' style={{ marginTop: '20px' }}>
                        <div style={{ margin: '20px 20px 0px 20px' }}>
                            <button onClick={navigateToUrl('/saved')} className='heading'>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Saved
                                </span>
                                <ChevronRightRounded style={{ color: 'rgb(120, 120, 120)', fontSize: '30px', verticalAlign: 'middle' }} />
                            </button>
                        </div>

                        {filteredRecentlySavedTrackInfos.map((trackInfo, index) => (
                            <div key={index} style={{ opacity: isTrackSaved(trackInfo.id) ? '1' : '0.3' }}>
                                <TrackInfoRow
                                    trackInfo={trackInfo}
                                    playTrack={playTrack}
                                    hideBottomBorder={index === filteredRecentlySavedTrackInfos.length - 1}
                                />
                            </div>
                        ))}

                        {allButton('More Saved', '/saved', filteredRecentlyPlayedTrackInfos.length !== 0 || filteredLatestSubscribedTrackInfos.length !== 0)}
                    </div>
                }

                {filteredLatestSubscribedTrackInfos.length === 0 ?
                    placeholder('Subscriptions', 'Subscribe to speakers and see their latest episodes here.', <img src='/subscribe_fill.png' style={{ width: '50px'}} />) :
                    <div className='animate__animated animate__fadeIn' >
                        <div style={{ margin: '20px 20px 0px 20px' }}>
                            <button onClick={navigateToUrl('/subscriptions')} className='heading'>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Subscriptions
                                </span>
                                <ChevronRightRounded style={{ color: 'rgb(120, 120, 120)', fontSize: '30px', verticalAlign: 'middle' }} />
                            </button>
                        </div>
                        {filteredLatestSubscribedTrackInfos.map((track, index) => (
                            <div key={index}>
                                <TrackInfoRow
                                    trackInfo={track}
                                    playTrack={playTrack}
                                    hideBottomBorder={index === filteredLatestSubscribedTrackInfos.length - 1}
                                />
                            </div>
                        ))}
                        {allButton('More Subscriptions', '/subscriptions')}
                    </div>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    latestSubscribedData: state.user.latestSubscribedData,
    recentlyPlayedData: state.user.recentlyPlayedData,
    recentlySavedData: state.user.recentlySavedData,
    savedTrackIds: state.user.savedTrackIds,
})

const mapActionsToProps = {
    loadLatestSubscribed,
    loadRecentlyPlayed,
    loadRecentlySaved,
}

export default connect(mapStateToProps, mapActionsToProps)(NextUpEpisodes);