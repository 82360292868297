import axios from 'axios';
import jwtDecode from 'jwt-decode';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { StyledFirebaseAuth } from 'react-firebaseui';


export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBBuG-peMIN98DKoX4IR1c1uTM1nEIa7U4",
    authDomain: "audio-twinkle.firebaseapp.com",
    projectId: "audio-twinkle",
    storageBucket: "audio-twinkle.appspot.com",
    messagingSenderId: "840715608354",
    appId: "1:840715608354:web:20bce3afdd6e13b1f6057b",
    measurementId: "G-EQJXSGGY6B"
};

export const setFirebaseToken = (token, refreshToken) => {
    const firebaseIdToken = `Bearer ${token}`;
    localStorage.setItem('FirebaseIdToken', firebaseIdToken);
    localStorage.setItem('FirebaseRefreshToken', refreshToken);
    axios.defaults.headers.common['Authorization'] = firebaseIdToken;
};

export const validTokenExists = () => new Promise((resolve, reject) => {
    const token = localStorage.FirebaseIdToken;
    if (token && token.startsWith('Bearer ') && localStorage.FirebaseRefreshToken) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            // Try to refresh token.
            delete axios.defaults.headers.common['Authorization'];
            axios.post('https://securetoken.googleapis.com/v1/token?key=' + FIREBASE_CONFIG.apiKey,
                'grant_type=refresh_token&refresh_token=' + localStorage.FirebaseRefreshToken,
                {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        setFirebaseToken(res.data.id_token, res.data.refresh_token);
                        resolve();
                    } else {
                        reject('Failure refreshing token.');
                    }
                })
        } else {
            // Token still valid.
            // console.log('token still valid! Remaining seconds: ' + (decodedToken.exp * 1000 - Date.now()) / 1000);
            return resolve();
        }
    } else {
        return reject('No existing token.');
    }
});

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

firebase.initializeApp(FIREBASE_CONFIG);
var auth = firebase.auth();

const loginPopupUI = <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

export { auth, loginPopupUI };