// Redux.
import { connect } from 'react-redux';

// React state and router.
import { useEffect, useState } from 'react'

// Components.
import { getAnalytics, logEvent } from "firebase/analytics";

// Relative time.
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { IosShareRounded } from '@mui/icons-material';
import { isAndroid } from '../components/deviceHelper';
import { MoreVert } from '@material-ui/icons';
import BottomHomeMenu from '../components/BottomHomeMenu';


const Install = ({ }) => {
    const analytics = getAnalytics();

    useEffect(() => {
        dayjs.extend(relativeTime);
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'install'
        });
    }, []);

    const [selectedAndroid, setSelectedAndroid] = useState(isAndroid());

    const androidButton = (
        <button className='deviceButton'
            onClick={() => setSelectedAndroid(true)}
            >
            <img style={{
                opacity: selectedAndroid ? 0.8 : 0.3,
                width: '30px'
            }} src='/android120.png' />
        </button>
    );

    const iosButton = (
        <button className='deviceButton'
            onClick={() => setSelectedAndroid(false)}
            >
            <img style={{
                opacity: !selectedAndroid ? 0.8 : 0.3,
                width: '30px'
            }} src='/apple120.png' />
        </button>
    )

    return (
        <div className="container bottomPadding">
             <BottomHomeMenu />
            <div className='mainWithBottomMenu animate__animated animate__fadeIn'>
                <div style={{ height: '474px', margin: '20px', padding: '20px', border: '1px solid rgb(234, 234, 234)', borderRadius: '20px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{}}>
                            <img src={selectedAndroid ? '/download_android.png' : '/download_ios.png'} style={{ maxHeight: '120px', borderRadius: '20px', objectFit: 'contain' }} />
                        </div>
                    </div>

                    <div style={{ textAlign: 'left', width: '300px', margin: '30px auto 0px' }}>
                        {selectedAndroid ?
                            <div style={{ fontSize: '16px', color: '#202124' }}>
                                <div style={{ marginTop: '20px' }}>
                                    <span className='circleNumber'>1</span>
                                    In <span style={{ fontWeight: '600' }}>Chrome</span>, tap <MoreVert style={{ marginBottom: '-4px', fontSize: '20px' }} />.

                                    <div style={{ marginLeft: '50px', marginTop: '10px' }}>
                                        <img src='/chrome_more.png' style={{ border: '1px solid rgb(248, 248, 248)', maxWidth: '200px', borderRadius: '20px', objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <span className='circleNumber'>2</span>
                                    Tap <span style={{ fontWeight: '600' }}>Install app</span>.
                                    <div style={{ marginLeft: '50px', marginTop: '10px' }}>
                                        <img src='/install_app.png' style={{ border: '1px solid rgb(248, 248, 248)', maxWidth: '200px', borderRadius: '20px', objectFit: 'contain' }} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ fontSize: '16px', color: '#202124' }}>
                                <div style={{ marginTop: '20px' }}>
                                    <span className='circleNumber'>1</span>
                                    In <span style={{ fontWeight: '600' }}>Safari</span>, tap <IosShareRounded style={{ marginBottom: '-4px', fontSize: '20px' }} />.

                                    <div style={{ marginLeft: '50px', marginTop: '10px' }}>
                                        <img src='/share_safari.png' style={{ maxWidth: '200px', borderRadius: '20px', objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <span className='circleNumber'>2</span>
                                    Tap <span style={{ fontWeight: '600' }}>Add to Home Screen</span>.
                                    <div style={{ marginLeft: '50px', marginTop: '10px' }}>
                                        <img src='/add_to_home.png' style={{ maxWidth: '200px', borderRadius: '20px', objectFit: 'contain' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    {isAndroid() ?
                        <div>
                            {androidButton}
                            {iosButton}
                        </div>
                        :
                        <div>
                            {iosButton}
                            {androidButton}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Install);