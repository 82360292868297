
import { connect } from 'react-redux';
import { setWPM } from '../redux/actions/userActions';
import { getAnalytics, logEvent } from "firebase/analytics";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { Add, Remove } from '@material-ui/icons';
import CloseFooter from './CloseFooter';

const WPMDrawer = ({
    defaultWPM,
    displayWPM,
    openWPM,
    toggleOpenWPM,

    // Redux.
    setWPM,
    wpm,
}) => {

    const analytics = getAnalytics();

    const minWPM = 100;
    const maxWPM = 400;

    const slowerWPM = () => {
        setWPM(Math.max(displayWPM - 10, minWPM));
    }

    const fasterWPM = () => {
        setWPM(Math.min(displayWPM + 10, maxWPM))
    }

    const resetWPM = () => {
        setWPM(null);
    }

    const setSpeedFn = (wpm) => () => {
        setWPM(wpm)
    }

    return (
        <div>
            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '20px 20px 0px 0px'
                    }
                }}
                anchor='bottom' open={openWPM} onClose={toggleOpenWPM(false)}>
                <Box
                    sx={{ width: 'auto' }}
                    role="presentation"
                >
                    <div className='horizontalCenter hasCloseFooter'>
                        <div style={{ padding: '3px 0px 44px', textAlign: 'center', width: '100%' }}>
                            <table style={{ margin: '30px auto 0px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ minWidth: '64px', paddingRight: '20px' }}>
                                            <button onClick={slowerWPM} className='slowerFasterButton'
                                                style={{ opacity: displayWPM <= 100 ? '0.24' : '1.0' }}>
                                                <Remove style={{ fontSize: '34px', verticalAlign: 'middle' }} />
                                            </button>
                                        </td>
                                        <td style={{ width: '180px' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <div style={{ fontWeight: '600', fontSize: '50px' }}>
                                                    {displayWPM}
                                                </div>
                                                <div style={{ marginTop: '-6px', fontSize: '18px', fontWeight: '600' }}>
                                                    words per minute
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '64px', paddingLeft: '20px' }}>
                                            <button onClick={fasterWPM} className='slowerFasterButton'
                                                style={{ opacity: displayWPM >= 400 ? '0.24' : '1.0' }}>
                                                <Add style={{ fontSize: '34px', verticalAlign: 'middle' }} />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <div style={{ padding: '40px 0px' }}>
                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ maxWidth: '380px', margin: '0px auto'}}>
                                        <div style={{ display: 'inline-block', width: '100%', padding: '0px 20px' }}>
                                            <div style={{ width: '100%', height: '4px', background: 'rgb(224, 224, 224)', float: 'left', borderRadius: '16px' }}>
                                                <div style={{ width: `${(displayWPM - 100) / 300 * 100}%`, height: '4px', background: 'rgb(80, 80, 80)', float: 'left', borderRadius: '16px' }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <button onClick={setSpeedFn(150)} className='speedPresetButton'>
                                        slow
                                    </button>
                                    <button onClick={setSpeedFn(250)} className='speedPresetButton'>
                                        fast
                                    </button>
                                    <button onClick={setSpeedFn(350)} className='speedPresetButton'>
                                        very fast
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button className={wpm === null ? 'originalSpeedButtonDisabled' : 'originalSpeedButton'} onClick={resetWPM}>
                                    original speed: {!defaultWPM ? '---' : defaultWPM}
                                </button>
                            </div>
                        </div>

                        <CloseFooter onClick={toggleOpenWPM(false)} />
                    </div>

                </Box>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => ({
    wpm: state.user.wpm,
})

const mapActionsToProps = {
    setWPM,
}

export default connect(mapStateToProps, mapActionsToProps)(WPMDrawer);
