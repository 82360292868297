import { useEffect } from 'react'


// Redux.
import { connect } from 'react-redux';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/compat/auth';

// Components.
import BottomHomeMenu from '../components/BottomHomeMenu';


const Missing = ({
}) => {

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'missing'
        });
    }, []);

    return (
        <div className="container">
            <BottomHomeMenu />
            <div className='mainWithBottomMenu'>
                <div className='center' style={{ marginTop: '-20px', fontSize: '20px', fontWeight: '500', minWidth: '300px', textAlign: 'center'}}>
                    No longer available
                </div>
            </div>
        </div>
    );
}

Missing.propTypes = {

}

Missing.defaultProps = {

}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Missing);