import { getAlgoliaResults } from '@algolia/autocomplete-js';

import { Autocomplete } from './Autocomplete';
import { searchClient } from './searchClient';
import { SearchRounded } from '@material-ui/icons';


const SearchBoxWithSuggestions = ({fixToTop, querySelected, initialQuery}) => {

    function TopicItem({ hit, components }) {
        
        const onClick = () => {
            querySelected(hit.query);
        }

        return (
            <button style={{ textAlign: 'left' }} onClick={onClick}>
                <span style={{ verticalAlign: 'middle' }}>
                    <SearchRounded style={{ fontSize: '26px', margin: '0px 6px 0px 8px', verticalAlign: 'middle', color: 'rgb(120, 120, 120)'}} />
                </span>
                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                    <components.Highlight hit={hit} attribute="query" />
                </span>
            </button>
        );
    }
    
    const onSubmit = () => {
        querySelected(document.getElementsByClassName('aa-Input')[0].value)
    }

    return (
        <div className={fixToTop ? 'algoliaCustom fixToTop' : 'algoliaCustom'}>
            <Autocomplete
                placeholder='Search speakers and topics'
                openOnFocus={true}
                onSubmit={onSubmit}
                initialState={{
                    query: initialQuery
                }}
                getSources={({ query }) => [
                    {
                        sourceId: 'sourceId',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'suggestions',
                                        query,
                                    },
                                ],
                            });
                        },
                        templates: {
                            item({ item, components }) {
                                return <TopicItem hit={item} components={components} />;;
                            },
                        },
                    },
                ]}
            />
        </div>
    );
}

export default SearchBoxWithSuggestions;