// Redux.
import { connect } from 'react-redux';
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AlgoliaSearch from '../components/AlgoliaSearch';
import ExampleSpeakerTopicIcons from '../components/ExampleSpeakerTopicIcons';
import SearchBoxWithSuggestions from '../components/SearchBoxWithSuggestions';

const Search = ({
}) => {
    let params = useParams();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const selectedSearchTopic = (topic) => {
        navigate(`/search/${encodeURIComponent(topic)}`)
    }

    const noQueryView = (
        <div style={{ padding: '60px 20px 0px' }}>
            <ExampleSpeakerTopicIcons searchQuery={selectedSearchTopic} />
        </div>
    )

    const querySelected = (query) => {
        // TODO fix this to prevent refresh.
        // navigate(`/search/${encodeURIComponent(query)}`);
        window.location.href = `/search/${encodeURIComponent(query)}`;
    }

    return (
        <div>
            <div id="homeContent" className='container hasCloseFooter hideScrollbar' style={{ height: window.innerHeight }}>
                <div className='horizontalCenter'>
                    <div>
                        <SearchBoxWithSuggestions fixToTop={true} querySelected={querySelected} initialQuery={params.query} />
                    </div>
                    <div className='thinDivider' />
                    <AlgoliaSearch noQueryView={noQueryView} initialQuery={params.query} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {

};

export default connect(mapStateToProps, mapActionsToProps)(Search);