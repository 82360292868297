import PropTypes from 'prop-types';
import axios from 'axios';

import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';
import {
    setRelatedTopicClips,
    setTrack,
} from '../redux/actions/dataActions'

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import ClipShareButton from './ClipShareButton';
import EntityInfoDrawer from './EntityInfoDrawer';
import SyncTranscriptAudio from './SyncTranscriptAudio';
import Transcript from './Transcript';
import WPMDrawer from './WPMDrawer';

import { fancyTimeFormat } from './timeHelper';
import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";
import { eventClipView, eventLog } from './eventHelper';

import Highlighter from 'react-highlight-words';

// Icons.
import { PauseRounded } from '@material-ui/icons';
import { PlayArrowRounded } from '@mui/icons-material';

import { RESPONSIVE_SMALL_HEIGHT } from '../constants/sizes';
import { LABEL_FOR_ME } from '../constants/labels';
import { TIP_PLAY_FIRST_CLIP } from '../constants/tips';


import { useLocation } from 'react-router-dom';
import { ACCENT_COLOR } from '../constants/colors';
import { validTokenExists } from '../firebaseHelper';


const ClipCard = ({
    clip,
    clipIndexInViewport,
    currentTime,
    index,
    nextClip,
    playClip,
    playing,
    timeRemaining,
    shouldLoadTrack,

    // Redux state.
    authenticated,
    currentClipsTopic,
    track,
    wpm,

    // Redux actions.
    setRelatedTopicClips,
    setTrack,
}) => {
    const clipCardViewport = useRef();
    const currentViewport = useRef();
    const transcriptContainerRef = useRef();
    const clipTopRef = useRef();
    const clipViewTimes = useRef(new Set());

    const audio = document.getElementById('audio');

    const analytics = getAnalytics();
    const isCurrentViewport = useIsInViewport(currentViewport);
    const [localClip, setLocalClip] = useState(null);
    const [missingClip, setMissingClip] = useState(false);
    const [scrollToCurrentMessage, setScrollToCurrentMessage] = useState(true);
    const [searchWordsFromCurrentTopic, setSearchWordsFromCurrentTopic] = useState([]);

    const contentRef = useRef(null)
    const clipSourceBottomRef = useRef(null);

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (scrollToCurrentMessage && audio.paused && localStorage.TIP_PLAY_FIRST_CLIP) {
            setTimeout(() => {
                audio.play().catch(function () { });
            }, 1000);
        }
    }, [scrollToCurrentMessage])

    useEffect(() => {
        if (!isCurrentViewport) {

        } else {
            clipIndexInViewport(index);
            if (localStorage.TIP_PLAY_FIRST_CLIP) {
                playClip(clip);
            }
            const pathName = `/clip/${clip.fullTrackId}/${clip.messageIndex}`;
            window.history.replaceState(null, '', pathName);
        }
    }, [isCurrentViewport]);

    useEffect(() => {
        const pathName = `/clip/${clip.fullTrackId}/${clip.messageIndex}`;
        if (shouldLoadTrack && !localClip) {
            if (track && track.pathname === pathName) {
                setLocalClip(track)
            } else {
                axios
                    .get(pathName)
                    .then((res) => {
                        setLocalClip(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMissingClip(true);
                    });
            }
        }
    }, [shouldLoadTrack]);

    useEffect(() => {
        if (!playing && isCurrentViewport && audio && audio.currentTime > 1) {
            setScrollToCurrentMessage(false);
        }
    }, [playing])

    useEffect(() => {
        if (isCurrentViewport && localClip) {
            setRelatedTopicClips(localClip.relatedTopicClips);
            setTrack(localClip);
            setScrollToCurrentMessage(true);
        }
        if (localClip) {
            setDefaultWPM(localClip.wpm);
        }
    }, [isCurrentViewport, localClip])

    const togglePlayPause = () => {
        checkAudioSource();
        if (playing) {
            audio.pause();
        } else {
            localStorage.setItem(TIP_PLAY_FIRST_CLIP, true);
            audio.play().catch(function () { });
        }
    }

    const checkAudioSource = () => {
        if (audio.src !== clip.mp3URL) {
            playClip(clip);
        }
        if (localClip) {
            setTrack(localClip);
        }
    }

    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const selectedSpeakerId = useRef('');

    const openSpeakerInfo = (speakerId) => () => {
        selectedSpeakerId.current = speakerId;
        setTimeout(() => {
            setOpenPersonInfo(true);
        }, 100);
    }

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPersonInfo(open);
    }

    useEffect(() => {
        if (isCurrentViewport && localStorage.TIP_PLAY_FIRST_CLIP) {
            if (openPersonInfo) {
                audio.pause();
            } else {
                checkAudioSource();
                audio.play().catch(function () { });
            }
        }
    }, [openPersonInfo, isCurrentViewport])

    const { state } = useLocation();

    const playPauseColor = 'rgb(30, 30, 30)';

    useEffect(() => {
        let searchWords = [];
        if (currentClipsTopic && currentClipsTopic !== LABEL_FOR_ME) {
            const withoutQuotes = currentClipsTopic.replaceAll('"', '');
            searchWords = withoutQuotes.split(' ');
        }
        setSearchWordsFromCurrentTopic(searchWords);
    }, [currentClipsTopic]);

    // WPM START.
    const [openWPM, setOpenWPM] = useState(false);
    const [displayWPM, setDisplayWPM] = useState(null);
    const [defaultWPM, setDefaultWPM] = useState(null);
    useEffect(() => {
        if (isCurrentViewport) {
            if (wpm) {
                setDisplayWPM(wpm)
            } else {
                setDisplayWPM(defaultWPM)
            }
            let playbackRate = 1.0;
            if (audio && displayWPM && defaultWPM) {
                playbackRate = displayWPM / defaultWPM;
            }
            if (playbackRate !== audio.playbackRate) {
                audio.playbackRate = playbackRate;
            }
        }
    }, [displayWPM, wpm, defaultWPM, isCurrentViewport])

    useEffect(() => {
        if (isCurrentViewport && audio && audio.src === clip.mp3URL) {
            const localClipViewTimes = clipViewTimes.current;
            const floorSeconds = Math.floor(currentTime);
            if (!localClipViewTimes.has(floorSeconds)) {
                localClipViewTimes.add(floorSeconds);
                clipViewTimes.current = localClipViewTimes;
                if (floorSeconds % 10 === 0) {
                    eventClipView(clip.id, floorSeconds);
                }
            }
        }
    }, [currentTime, isCurrentViewport])

    const toggleOpenWPM = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenWPM(open);
    }
    // WPM END.

    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"));

    const trackDifferentFromLocalTrack = (t1, t2) => {
        if (!t1 || !t2) {
            return true;
        }
        return t1.mp3URL !== t2.mp3URL;
    }

    const clipTitleClassName = height > RESPONSIVE_SMALL_HEIGHT ? 'clipTitleLarge' : 'clipTitle'

    const clipTop = (
        <div ref={clipTopRef} className='clipTopInfo' style={{ zIndex: '99' }}>
            <div className={clipTitleClassName}>
                <Highlighter
                    highlightClassName="highlight"
                    searchWords={searchWordsFromCurrentTopic}
                    autoEscape={true}
                    textToHighlight={clip.title}
                />
            </div>
        </div>
    );

    return (
        <div id={clip.id} ref={clipCardViewport} className='clip-slider hideScrollbar'
            style={{
                opacity: isCurrentViewport ? 1.0 : 0.6, width: `${Math.min(width, 540) - 40}px`,
                height: `${height - 84 - safeAreaBottom}px`
            }}>
            <EntityInfoDrawer
                open={openPersonInfo}
                onClose={togglePersonInfo(false)}
                speakerId={selectedSpeakerId.current} />

            <WPMDrawer
                defaultWPM={defaultWPM}
                displayWPM={displayWPM}
                openWPM={openWPM}
                toggleOpenWPM={toggleOpenWPM}
            />
            <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
            </div>
            {missingClip ?
                <div className='heading center' style={{ paddingBottom: '20px', minWidth: '280px', textAlign: 'center' }} >
                    <div>
                        No longer available
                    </div>
                    <div style={{ padding: '20px' }}>
                        <button onClick={nextClip} style={{ paddingLeft: '14px', fontWeight: '600', fontSize: '20px', color: ACCENT_COLOR }}>
                            <span style={{ verticalAlign: 'middle' }}>
                                Next Clip
                            </span>
                        </button>
                    </div>
                </div>
                :
                <div ref={contentRef}>
                    {clipTop}
                    {!isCurrentViewport || trackDifferentFromLocalTrack(localClip, track) ?
                        <div className={isCurrentViewport ? 'glow' : ''}>
                            <div style={{ padding: '16px', overflowY: 'auto' }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='loading' style={{ marginRight: '10px', height: '44px', width: '44px', borderRadius: '50%' }} />
                                            </td>
                                            <td>
                                                <div className='loading' style={{ borderRadius: '6px', marginBottom: '6px', height: '22px', width: '160px' }} />
                                                <div className='loading' style={{ borderRadius: '6px', height: '16px', width: '200px' }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                                <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '180px', width: '100%' }} />
                            </div>
                        </div>
                        :
                        <div >
                            <div>
                                <div style={{ overflowY: 'auto', opacity: !localStorage.TIP_PLAY_FIRST_CLIP ? 0.3 : 1.0 }}>
                                    <div ref={transcriptContainerRef}>
                                        <Transcript
                                            pathname={localClip.pathname}
                                            autoscrollPaddingTop={clipTopRef.current ?
                                                clipTopRef.current.clientHeight + (height < RESPONSIVE_SMALL_HEIGHT ? 41 : 82) : 150}
                                            curTime={currentTime}
                                            currentSection={0}
                                            isClip={true}
                                            openSpeakerInfo={openSpeakerInfo}
                                            playing={playing}
                                            scrollToCurrentMessage={scrollToCurrentMessage}
                                            setScrollToCurrentMessage={setScrollToCurrentMessage}
                                            transcriptContainerRef={clipCardViewport}
                                            topContent={<></>}
                                            bottomContent={<></>}
                                        />
                                    </div>
                                </div>


                                <SyncTranscriptAudio
                                    bottom={clipSourceBottomRef.current ? `${clipSourceBottomRef.current.clientHeight + 8}px` : '0px'}
                                    currentTime={currentTime}
                                    scrollToCurrentMessage={scrollToCurrentMessage}
                                    setScrollToCurrentMessage={setScrollToCurrentMessage}
                                    track={localClip}
                                />

                                {!localStorage.TIP_PLAY_FIRST_CLIP ?
                                    <></>
                                    :
                                    <div ref={clipSourceBottomRef} className='clipSourceBottom'>
                                        <table style={{ borderSpacing: 0, width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ minWidth: '150px' }}>
                                                        <button className='timeRemaining' onClick={togglePlayPause}>
                                                            {playing ?
                                                                <PauseRounded style={{ fontSize: '34px', color: playPauseColor, verticalAlign: 'middle', marginTop: '-2px' }} /> :
                                                                <PlayArrowRounded style={{ fontSize: '34px', color: playPauseColor, verticalAlign: 'middle', marginTop: '-2px' }} />

                                                            }
                                                            <span style={{ color: playPauseColor, paddingLeft: '16px', verticalAlign: 'middle' }}>
                                                                {!timeRemaining || !isCurrentViewport ?
                                                                    <span
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            fontWeight: '400',
                                                                            fontSize: '14px'
                                                                        }}>
                                                                        Play
                                                                    </span>
                                                                    :
                                                                    `-${fancyTimeFormat(timeRemaining)}`}
                                                            </span>
                                                        </button>
                                                    </td>
                                                    <td style={{ width: '100%', textAlign: 'right' }}>
                                                        {!localClip || !localStorage.TIP_PLAY_FIRST_CLIP ? <></>
                                                            :
                                                            <div>
                                                                <button onClick={toggleOpenWPM(true)} className='wpmButton'>
                                                                    {displayWPM} WPM
                                                                </button>
                                                                <ClipShareButton
                                                                    buttonStyle={{ verticalAlign: 'middle', padding: '0px 6px 0px 16px' }}
                                                                    iconStyle={{ padding: '1px', color: playPauseColor, fontSize: '24px' }}
                                                                    trackId={localClip.fullTrackId}
                                                                    messageIndex={localClip.transcript[0].messageIndex}
                                                                    title={localClip.title}
                                                                    summary={localClip.summary}
                                                                    clipLength={audio.duration}
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div >
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    currentClipsTopic: state.data.currentClipsTopic,
    track: state.data.track,
    wpm: state.user.wpm,
})

const mapActionsToProps = {
    setRelatedTopicClips,
    setTrack,
}

ClipCard.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(ClipCard);