import { forwardRef, useState } from "react";

import { getAnalytics, logEvent } from "firebase/analytics";
import ButtonTipPopup from "./ButtonTipPopup";

import Modal from '@mui/material/Modal';

import { IosShareRounded, LinkRounded, WebStoriesOutlined } from "@mui/icons-material";
import { LABEL_APPNAME, LABEL_DOMAIN } from "../constants/labels";
import { isIPhone } from "./deviceHelper";
import { eventLog } from "./eventHelper";


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const TopicSpeakerClips = ({
    hideTopic,
    playTopic,
    topic,
    width,
    overrideSpeakersPerRow,
    showAll,
}) => {
    const profilePicMarginRight = 20;
    const speakersPerRow = overrideSpeakersPerRow ? overrideSpeakersPerRow : width > 400 ? 4 : 3;
    const maxSpeakers = showAll ? 1000 : speakersPerRow * 2;
    const buffer = (speakersPerRow - 1) * (profilePicMarginRight + 1) / speakersPerRow;

    const analytics = getAnalytics();

    const [showCopied, setShowCopied] = useState(false);
    const toggleShowCopied = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowCopied(open);
    }

    const playTopicClick = (topic, clipId) => () => {
        let clip = null;
        if (clipId) {
            for (let i = 0; i < topic.clips.length; i++) {
                if (topic.clips[i].id === clipId) {
                    clip = topic.clips[i];
                    break;
                }
            }
        }

        if (clip !== null) {
            eventLog(3, {
                'clipId': clip.id,
                'speakerId': clip.person.id,
                'topicId': topic.id,
            })
        } else {
            eventLog(4, {
                'topicId': topic.id,
            })
        }

        playTopic(topic, clipId)();
    }

    const shareTopic = () => {
        const link = `https://${LABEL_DOMAIN}/topic/${encodeURIComponent(topic.id)}`;
        logEvent(analytics, 'share_topic');
        if (navigator.share && isIPhone()) {
            const shareText = 'Podcast previews on "' + topic.id + '"\n\n' + link;
            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => { })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            navigator.clipboard.writeText(link);
            setShowCopied(true);
        }
    }

    const uniqueSpeakerClips = (clips) => {
        const speakerIds = new Set();
        const speakerClips = [];
        for (let i = 0; i < clips.length; i++) {
            const clip = clips[i];
            const clipSpeakerId = clip.person.id;
            if (!speakerIds.has(clipSpeakerId)) {
                speakerIds.add(clipSpeakerId)
                speakerClips.push(clip);
            }
        }
        return speakerClips;
    }

    return (
        <div>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
            // onClose={toggleShowCopied(false)}
            >
                <ButtonTipPopupRef close={toggleShowCopied(false)} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>

            {!!hideTopic ?
                <></>
                :
                <table style={{ margin: '16px 0px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '100%' }}>
                                <button onClick={playTopicClick(topic)} >
                                    <div style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                                        <div className='topicName'>{topic.id}</div>
                                    </div>
                                </button>
                            </td>
                            <td>
                                <button onClick={playTopicClick(topic)} >
                                    <WebStoriesOutlined style={{ fontSize: '24px', marginRight: '16px', verticalAlign: 'middle' }} />
                                </button>
                            </td>
                            <td>
                                <button onClick={shareTopic}>
                                    <IosShareRounded style={{ marginLeft: '16px', marginRight: '14px', verticalAlign: 'middle', fontSize: '24px' }} />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            }
            <div className='subheading' style={{ fontSize: '14px', paddingBottom: '16px' }}>
                Trending speakers on "{topic.id}"
            </div>
            {
                uniqueSpeakerClips(topic.clips).slice(0, maxSpeakers).map((clip, speakerClipIndex) => (
                    <button key={speakerClipIndex} onClick={playTopicClick(topic, clip.id)} style={{ verticalAlign: 'top', marginBottom: '16px', marginRight: (speakerClipIndex + 1) % speakersPerRow === 0 ? '0px' : `${profilePicMarginRight}px` }}>
                        <div>
                            <img alt='profile' src={clip.person.imageURL} style={{ minWidth: `${width / speakersPerRow - buffer}px`, minHeight: `${width / speakersPerRow - buffer}px`, width: `${width / speakersPerRow - buffer}px`, border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                            <div className='profilePictureName' style={{ maxWidth: `${width / speakersPerRow - buffer}px` }}>
                                {clip.person.name}
                            </div>
                        </div>
                    </button>
                ))
            }
        </div>
    );
};

export default TopicSpeakerClips;