// Redux.
import { connect } from "react-redux";

// Components.

import { useNavigate } from "react-router-dom";
import { HomeRounded } from "@material-ui/icons";


const BottomHomeMenu = ({
}) => {

    const navigate = useNavigate();

    return (
        <div className='footer'>
            <div className='horizontalCenter'>
                <div className='bottomHomeMenu' style={!(window.location.pathname.startsWith('/clip') || window.location.pathname.startsWith('/')) ? { background: 'rgb(248, 248, 248)' } : {}}>
                    <button className='menuButton' onClick={() => navigate('/')}>
                        <span className='menuButtonTitle'>
                            <HomeRounded style={{ verticalAlign: 'middle', fontSize: '30px' }} />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(BottomHomeMenu);