import { connect } from 'react-redux';
import { useRef } from 'react';
import { useState } from 'react';

import { ArrowCircleRight, IosShare, LinkRounded } from '@mui/icons-material';
import { Drawer, Modal } from '@mui/material';

import ProfilePicture from './ProfilePicture';
import { report } from './eventHelper';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ButtonTipPopup from './ButtonTipPopup';
import { forwardRef } from 'react';
import { isIPhone } from './deviceHelper';
import { LABEL_APPNAME, LABEL_DOMAIN } from '../constants/labels';


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const EditMessage = ({
    message,
    speaker,

    // Redux.
    authenticated,
    track,
}) => {
    const analytics = getAnalytics();

    const keyboardContainerRef = useRef(null);
    const [commentText, setCommentText] = useState('');

    const messageText = () => {
        const words = [];
        for (let i = 0; i < message.message.length; i++) {
            words.push(message.message[i].text);
        }
        return words.join('');
    };

    const [showCopied, setShowCopied] = useState(false);

    const onShareLinkClick = () => {
        let startAt = track.clipStartTime ? track.clipStartTime : 0;
        startAt += message.timeSeconds;
        const link = `https://${LABEL_DOMAIN}/track/${track.fullTrackId ?? track.id}?start=${Math.floor(startAt)}s`;

        logEvent(analytics, 'message_share_link');
        if (navigator.share && isIPhone()) {
            let shareText = 'Thought you might like this';
            shareText += '\n\n' + link;

            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => {
                })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            navigator.clipboard.writeText(link);
            setShowCopied(true);
        }
    }

    const closeCopied = () => {
        setShowCopied(false);
    }

    return (

        <div className='hideScrollbar'>

            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
                onClose={closeCopied}
            >
                <ButtonTipPopupRef close={closeCopied} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>

            <div className="container preventSelect">
                <div className='horizontalCenter' style={{ padding: '20px' }}>
                    <div>
                        <table style={{
                            width: '100%',
                        }}>
                            <tbody>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <td style={{ width: '62px' }}>
                                        <ProfilePicture
                                            imageSrc={speaker.imageURL}
                                            color='white'
                                            name={speaker.name}
                                            width='52px'
                                            height='62px'
                                            margin='6px 12px 0px -2px' />
                                    </td>
                                    <td>
                                        <div className='speakerNameSmall'>
                                            {speaker.name}
                                        </div>
                                        <div className='speakerDescription'>
                                            {speaker.description}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        {messageText()}
                    </div>

                    <div style={{ padding: '20px 0px' }}>
                        <button onClick={onShareLinkClick} style={{ textAlign: 'left', border: '1px solid rgb(224, 224, 224)', padding: '12px 20px', width: '100%', borderRadius: '8px' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '44px' }}>
                                            <IosShare style={{ fontSize: '30px', marginRight: '10px' }} />
                                        </td>
                                        <td>
                                            <div style={{ fontSize: '16px', fontWeight: '500' }}>
                                                Podcast Episode
                                            </div>
                                            <div style={{ fontSize: '14px', color: 'rgb(80, 80, 80)' }}>
                                                Starts playing from current message
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    track: state.data.track,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EditMessage);
