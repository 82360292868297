import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';

// Router.
import { Navigate } from 'react-router-dom';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import { loadTrack } from '../components/audioHelper';
import AudioPlayerDrawer from '../components/AudioPlayerDrawer';
import RecentlyPlayed from '../components/RecentlyPlayed';
import BottomHomeMenu from '../components/BottomHomeMenu';


const Recent = ({
    authenticated,
}) => {

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);
    const trackIdRef = useRef(null)

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'recent'
        });
    }, []);

    const playTrack = (trackId, mp3URL, startSeconds) => () => {
        trackIdRef.current = trackId;
        loadTrack(mp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
    };
    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
    }

    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <AudioPlayerDrawer
                        open={showAudioPlayerDrawer}
                        onClose={closeAudioPlayer}
                        trackId={trackIdRef.current} />
                <BottomHomeMenu />
                <div className='mainWithBottomMenu'>
                    <RecentlyPlayed playTrack={playTrack} />
                </div>
            </div>
        );
    }
}

Recent.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

Recent.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Recent);