import { useEffect } from 'react'

// Redux.
import { connect } from 'react-redux';
import { loadForMe } from '../redux/actions/userActions';

// Firebase.
import { auth } from '../firebaseHelper';
import { useAuthState } from 'react-firebase-hooks/auth';

// Components.
import HomeContent from '../components/HomeContent';
import { isTooOld } from '../components/timeHelper';

const Home = ({
    authenticated,
    forMeLoading,
    forMeData,
    loadForMe,
}) => {

    const [user, userLoading, error] = useAuthState(auth);

    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"));

    useEffect(() => {
        if (
            authenticated &&
            !forMeLoading &&
            (!forMeData || isTooOld(forMeData.setTime, 300))
        ) {
            loadForMe();
        }
    }, [authenticated])

    useEffect(() => {
        const audio = document.getElementById('audio');
        audio.pause();
    }, [])

    return (
        <div className="container">
            <div className='main'>
                {userLoading || (user && !forMeData) ?
                    <div>
                        <div className='center'>
                            <div style={{ paddingBottom: `${safeAreaBottom + 20}px` }}>
                                <img className='glow icon' src='/icon.png' />
                            </div>
                        </div>
                    </div>
                    :
                    <HomeContent />
                }
            </div>
        </div >
    )
}

Home.propTypes = {

}

Home.defaultProps = {
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    forMeData: state.user.forMeData,
    forMeLoading: state.user.forMeLoading,
})

const mapActionsToProps = {
    loadForMe,
}

export default connect(mapStateToProps, mapActionsToProps)(Home);