import { useEffect } from 'react'

export default function ButtonTipPopup(props) {
    const { content, close } = props;

    useEffect(() => {
        const timeout = setTimeout(() => {
            close();
        }, 2000)
        // effect cleanup
        return () => {
            clearTimeout(timeout);
        }
    }, [])


    return (
        <div className='center'>
            <div className='blurBackground' style={{ borderRadius: '20px', padding: '48px 0px', width: '280px', textAlign: 'center' }}>
                {content}
            </div>
        </div>
    );
}
