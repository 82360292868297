import { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useSearchParams, Navigate } from 'react-router-dom';


// Redux.
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';

// Router.
import { useNavigate } from "react-router-dom";

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";
import { auth, loginPopupUI } from '../firebaseHelper'

import BottomHomeMenu from '../components/BottomHomeMenu';

const Login = ({ loginUser, authenticated }) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const redirectAfterLogin = () => {
        let redirect = searchParams.get('redirect');
        if (redirect !== null && redirect.startsWith('/')) {
            return redirect;
        }
        return '/home';
    }

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'login'
        });
    }, []);

    // Listen to the Firebase Auth state and set redux state.
    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if (!!user) {
                user.getIdToken().then(function (idToken) {
                    loginUser(idToken, user.refreshToken, {});
                    const analytics = getAnalytics();
                    logEvent(analytics, 'login');
                    navigate(redirectAfterLogin());
                });
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!authenticated) {
        return (
            <div className="container">

                <div className='mainWithBottomMenu'>
                    <div style={{ padding: '16px 20px', marginBottom: '16px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                        <div className='heading' style={{ fontSize: '14px' }}>
                            Log in or sign up
                        </div>
                    </div>
                    {loginPopupUI}
                </div>
                <BottomHomeMenu />
            </div>
        );
    } else {
        return <Navigate to={redirectAfterLogin()}></Navigate>
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired
}

Login.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
})

const mapActionsToProps = {
    loginUser
}

export default connect(mapStateToProps, mapActionsToProps)(Login);