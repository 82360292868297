import update from 'immutability-helper';

import {
  ADD_SAVED_TRACK_IDS,
  LOAD_FOR_ME,
  LOAD_FOR_ME_TOPICS,
  LOAD_LATEST_SUBSCRIED,
  LOAD_RECENTLY_PLAYED,
  LOAD_RECENTLY_SAVED,
  SET_AUTHENTICATED,
  SET_FOR_ME,
  SET_FOR_ME_TOPICS,
  SET_LATEST_SUBSCRIED,
  SET_RECENTLY_PLAYED,
  SET_RECENTLY_SAVED,
  SET_SAVE_TRACK_STATUS,
  SET_SUBSCRIBE_STATUS,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_WPM,
  SHUFFLE_FOR_ME_TOPICS,
} from '../types';
import { shuffle } from 'lodash';

const initialState = {
  authenticated: null,
  forMeLoading: false,
  forMeData: null,
  forMeTopics: [],
  // null means loading.
  forMeTopicsUpdated: 0,
  latestSubscribedData: null,
  latestSubscribedLoading: false,
  recentlyPlayedData: null,
  recentlyPlayedLoading: false,
  recentlySavedData: null,
  recentlySavedLoading: false,
  savedTrackIds: [],
  subscribeIds: [],
  phoneNumber: '',
  wpm: null,
};


function shuffleForMeTopics(forMeTopics) {
  if (!forMeTopics) {
      return forMeTopics;
  }
  const shuffledTopics = shuffle(forMeTopics);
  for (let i = 0; i < shuffledTopics.length; i++) {
      shuffledTopics[i].clips = shuffle(shuffledTopics[i].clips);
  }
  return shuffledTopics;
}

const updateSavedTrackIds = (savedTrackIds, trackId, saveStatus) => {
  const newSavedTrackIds = !savedTrackIds ? [] : savedTrackIds.filter((savedTrackId) => {
    return savedTrackId !== trackId
  })
  if (saveStatus) {
    newSavedTrackIds.push(trackId);
  }
  return newSavedTrackIds;
};

const updateSubscribeIds = (subscribeIds, subscribeId, subscribeStatus) => {
  const newSubscribeIds = !subscribeIds ? [] : subscribeIds.filter((savedSubscribeId) => {
    return savedSubscribeId !== subscribeId
  })
  if (subscribeStatus) {
    newSubscribeIds.push(subscribeId);
  }
  return newSubscribeIds;
};

const addSavedTrackIds = (savedTrackIds, newTrackIds) => {
  return [...new Set([...savedTrackIds, ...newTrackIds])]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SAVED_TRACK_IDS:
      return {
        ...state,
        savedTrackIds: addSavedTrackIds(state.savedTrackIds, action.payload.trackIds),
      };
    case LOAD_FOR_ME:
      return {
        ...state,
        forMeData: null,
        forMeLoading: true,
      };
    case LOAD_FOR_ME_TOPICS:
      return {
          ...state,
          forMeTopics: [],
          forMeTopicsUpdated: null,
      };
    case LOAD_LATEST_SUBSCRIED:
      return {
        ...state,
        latestSubscribedLoading: true,
      };
    case LOAD_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayedLoading: true,
      };
    case LOAD_RECENTLY_SAVED:
      return {
        ...state,
        recentlySavedLoading: true,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_FOR_ME:
      const forMeData = action.payload;
      if (forMeData) {
        forMeData.setTime = Date.now();
      }
      return {
        ...state,
        forMeData: forMeData,
        forMeLoading: false,
      };
    case SET_FOR_ME_TOPICS:
      return {
          ...state,
          forMeTopics: action.payload,
          forMeTopicsUpdated: Date.now(),
      };
    case SET_LATEST_SUBSCRIED:
      const latestSubscribedData = action.payload;
      if (latestSubscribedData) {
        // TODO move this to server side.
        latestSubscribedData.setTime = Date.now();
      }
      return {
        ...state,
        latestSubscribedData: latestSubscribedData,
        latestSubscribedLoading: false,
      };
    case SET_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayedData: action.payload,
        recentlyPlayedLoading: false,
      };
    case SET_RECENTLY_SAVED:
      return {
        ...state,
        recentlySavedData: action.payload,
        recentlySavedLoading: false,
        savedTrackIds: addSavedTrackIds(state.savedTrackIds, action.payload ? action.payload.trackInfos.map((x) => x.id) : []),
      };
    case SET_SUBSCRIBE_STATUS:
      return {
        ...state,
        subscribeIds: updateSubscribeIds(state.subscribeIds, action.payload.subscribeId, action.payload.subscribeStatus),
      };
    case SET_UNAUTHENTICATED:
      return {
        ...initialState,
        authenticated: false
      };
    case SET_USER:
      return {
        ...state,
        ...action.payload
      };
    case SET_SAVE_TRACK_STATUS:
      return {
        ...state,
        savedTrackIds: updateSavedTrackIds(state.savedTrackIds, action.payload.trackId, action.payload.saveStatus),
      }
    case SET_WPM:
      return {
        ...state,
        wpm: action.payload
      }
    case SHUFFLE_FOR_ME_TOPICS:
      return {
          ...state,
          forMeTopics: shuffleForMeTopics(state.forMeTopics)
      }
    default:
      return state;
  }
}