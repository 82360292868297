import { Apple } from "@material-ui/icons";
import { getAnalytics, logEvent } from 'firebase/analytics';


const TestFlight = ({ }) => {
    const analytics = getAnalytics();

    const onClick = () => {
        logEvent(analytics, 'testflight_click');
    }

    return (
        <a href='https://testflight.apple.com/join/MVjCygji' target='_blank'>
            <button onClick={onClick}>
                <div className='homeButton'>
                    <Apple style={{ verticalAlign: 'middle', fontSize: '22px', color: 'rgb(80, 80, 80)', color: 'white' }} />
                    <span style={{ fontWeight: '500', paddingTop: '2px', display: 'inline-block', fontSize: '16px', verticalAlign: 'middle', marginLeft: '8px' }}>
                        Get iPhone app
                    </span>
                </div>
            </button>
        </a>
    );
};

export default TestFlight;
