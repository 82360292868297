const Loading = ({ overrideStyle, text, subText }) => {
    const style = overrideStyle ? overrideStyle : { overflow: 'hidden', height: '100vh', textAlign: 'center', width: '100vw' };
    return (
        <div>
            <div style={style}>
                <div className={overrideStyle ? '' : 'center'}>
                    {!text ? <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        :
                        <div
                            className='glow'
                            style={{ padding: '20px', minWidth: '300px' }}>
                            <div className='heading'>
                                {text}
                            </div>
                            <div style={{ paddingTop: '2px', fontSize: '18px', color: 'rgb(120, 120, 120)' }}>
                                {subText ? subText : "Loading"}
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default Loading;
