// Redux.
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
    loadForMe,
    updateForMe,
} from "../redux/actions/userActions";
import {
    setCurrentClipsTopic,
    setNextClipsInfo,
} from "../redux/actions/dataActions";
import { useNavigate } from "react-router-dom";
import { LABEL_FOR_ME } from "../constants/labels";
import { loadTrack } from "./audioHelper";
import { eventClipView } from "./eventHelper";
import ClipInfoLoading from "./ClipInfoLoading";


const ForMePreview = ({
    // Redux.
    authenticated,
    forMeData,
    forMeLoading,
    loadForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
    track,
    updateForMe,
}) => {

    const [previewClips, setPreviewClips] = useState([]);

    useEffect(() => {
        if (authenticated && !forMeData) {
            loadForMe();
        }
    }, [authenticated]);

    const audio = document.getElementById('audio');
    useEffect(() => {
        if (!forMeData) {
            setPreviewClips([]);
        } else {
            // Exclude last played clip and all clips before it from preview.
            let currentTrackInForMeClips = false;
            if (audio.currentTime > 0 && track && audio.src === track.mp3URL) {
                const nextPreviewTracks = [];
                for (let i = 0; i < forMeData.clips.length; i++) {
                    if (currentTrackInForMeClips) {
                        nextPreviewTracks.push(forMeData.clips[i]);
                    }
                    if (forMeData.clips[i].mp3URL === track.mp3URL) {
                        currentTrackInForMeClips = true;
                    }
                }
                if (nextPreviewTracks.length > 0) {
                    setPreviewClips(nextPreviewTracks.slice(0, 3));
                }
            }
            if (!currentTrackInForMeClips) {
                setPreviewClips(forMeData.clips.slice(0, 3));
            }
        }
    }, [track, forMeData])

    const navigate = useNavigate();

    const playClip = (clip) => () => {
        let clipIndex = 0;
        for (let i = 0; i < forMeData.clips.length; i++) {
            if (clip.id === forMeData.clips[i].id) {
                clipIndex = i;
                break;
            }
        }
        const clips = forMeData.clips.slice(clipIndex, forMeData.clips.length).concat(forMeData.clips.slice(0, clipIndex))
        loadTrack(clips[0].mp3URL, 0)
        setCurrentClipsTopic(LABEL_FOR_ME);
        setNextClipsInfo(LABEL_FOR_ME, clips);
        navigate('/clips', { state: { closeAllModal: true } });
    }

    const notInterestedClick = (clips) => () => {
        const removeClipIds = new Set();
        for (let i = 0; i < clips.length; i++) {
            eventClipView(clips[i].id, 0);
            removeClipIds.add(clips[i].id)
        }
        const newForMeClips = [];
        for (let i = 0; i < forMeData.clips.length; i++) {
            if (!removeClipIds.has(forMeData.clips[i].id)) {
                newForMeClips.push(forMeData.clips[i]);
            }
        }
        setPreviewClips([]);
        // TODO handle this better as data can change server side.
        updateForMe({
            uid: forMeData.uid,
            clips: newForMeClips,
        });
    }

    return (
        <div>
            {forMeLoading ?
                <div style={{ margin: '20px 0px' }}>
                    <ClipInfoLoading marginBottom='20px' />
                    <ClipInfoLoading marginBottom='20px' />
                    <ClipInfoLoading />
                </div>
                :
                previewClips.length < 1 ? <></> :
                    <div style={{ paddingTop: '20px' }} className='animate__animated animate__fadeIn'>
                        {previewClips.map((clip, clipIndex) => (
                            <button key={clipIndex} onClick={playClip(clip)} style={{ marginBottom: '16px' }}>
                                <table style={{ textAlign: 'left' }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img alt='profile' src={clip.person.imageURL} style={{ marginRight: '20px', width: '78px', height: '78px', border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                                            </td>
                                            <td>
                                                <div className='clipSearchResultTitle'>
                                                    {clip.title}
                                                </div>
                                                <div style={{ marginTop: '6px' }}>
                                                    <div className='speakerNameSmall'>
                                                        {clip.person.name}
                                                    </div>
                                                    <div className='speakerDescription'>
                                                        {clip.person.description}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </button>
                        ))}
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={notInterestedClick(previewClips)} className='newSuggestions'>
                                New Suggestions
                            </button>
                        </div>
                    </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    forMeData: state.user.forMeData,
    forMeLoading: state.user.forMeLoading,
    track: state.data.track,
})

const mapActionsToProps = {
    loadForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
    updateForMe,
}

export default connect(mapStateToProps, mapActionsToProps)(ForMePreview);