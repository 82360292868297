export const isAndroid = () => {
    return navigator.userAgent.includes('Android');
}

export const isIPhone = () => {
    return navigator.userAgent.includes('iPhone');
}

export const isWindows = () => {
    return navigator.userAgent.includes('Windows');
}