import { RAINBOW } from "../constants/colors"

export const getSpeakerData = (speakerId, speakersData) => {
    if (!speakersData) {
        return null;
    }
    for (let i = 0; i < speakersData.length; i++) {
        const speakerData = speakersData[i]
        if (speakerData.id === speakerId) {
            return speakerData;
        }
    }
    return null;
}

export const infoLink = (speakerId, speakersData) => {
    const speakerData = getSpeakerData(speakerId, speakersData)
    if (!speakerData) {
        return ''
    }
    return speakerData.moreInfoURL;
}

export const infoLinkThumbnail = (infoLink) => {
    if (infoLink.includes('.linkedin.')) {
        return '/linkedin100.png';
    }
    if (infoLink.includes('.twitter.')) {
        return '/twitter100.png';
    }
    return '/wiki100.png';
}

export const speakerName = (speakerId, speakersData) => {
    const speakerData = getSpeakerData(speakerId, speakersData)
    if (!speakerData) {
        return ''
    }
    return speakerData.name
}

export const speakerDescription = (speakerId, speakersData) => {
    const speakerData = getSpeakerData(speakerId, speakersData)
    if (!speakerData) {
        return ''
    }
    return speakerData.description
}

export const speakerImageSrc = (speakerId, speakersData) => {
    const speakerData = getSpeakerData(speakerId, speakersData)
    if (!speakerData) {
        return ''
    }
    return speakerData.imageURL
}