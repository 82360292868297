// React state and router.
import { useEffect, useState } from 'react'

// Redux.
import { connect } from 'react-redux';
import {
    setCurrentClipsTopic,
    setNextClipsInfo,
} from '../redux/actions/dataActions';

import {
    loadForMe,
} from '../redux/actions/userActions';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";


// Components.
import AudioPlayerDrawer from '../components/AudioPlayerDrawer';
import BookmarkButton from '../components/BookmarkButton';
import ClipCard from '../components/ClipCard';
import Loading from '../components/Loading';

// Component helpers.
import { loadTrack } from "../components/audioHelper"

// Constants.
import { LABEL_FOR_ME } from '../constants/labels';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../components/windowHelper';
import { ChevronLeft, ChevronRight, HomeRounded } from '@material-ui/icons';
import { eventLog } from '../components/eventHelper';
import { PlayCircleRounded } from '@mui/icons-material';
import { ACCENT_COLOR } from '../constants/colors';
import { TIP_PLAY_FIRST_CLIP } from '../constants/tips';

const _NUM_CLIPS_TO_PREFETCH = 1;

const Clips = ({
    // Redux state.
    authenticated,
    currentClipsTopic,
    forMeData,
    forMeLoading,
    nextClipsInfo,
    // TODO create new redux field clip that differs from track.
    // Currently both clips and track use "track" field.
    // A clip has additional fields such as "fullTrackId" and "relatedTopicClips"
    track,

    // Redux actions.
    loadForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
}) => {
    const analytics = getAnalytics();
    const audio = document.getElementById('audio');
    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    const [currentClipIndex, setCurrentClipIndex] = useState(0);

    const [curTime, setCurTime] = useState(0);
    const [duration, setDuration] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);

    const clipIndexInViewport = (clipIndex) => {
        setCurrentClipIndex(clipIndex);
        setContinuePercent(-1);
        setTimeRemaining(NaN);
    }

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
        if (!state || !state.closeAllModal) {
            setTimeout(() => {
                nextClip();
            },
                // Delay to allow audio player drawer close animation to finish.
                300)
        }
    }

    useEffect(() => {
        if (!authenticated && !nextClipsInfo) {
            navigate('/');
        }

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'clips'
        });

        const setAudioTime = () => {
            setCurTime(audio.currentTime);
            const remaining = (audio.duration - audio.currentTime) / audio.playbackRate;
            setTimeRemaining(remaining);
            if (!playing && !audio.paused) {
                setPlaying(true);
            }
            if (!duration) {
                setDuration(audio.duration);
            }
        }

        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }

        // DOM listeners: update React state on DOM events
        // audio.addEventListener('loadeddata', setAudioData);
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            // audio.removeEventListener('loadeddata', setAudioData);
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    useEffect(() => {
        if (authenticated && !nextClipsInfo) {
            if (!forMeLoading) {
                loadForMe();
            }
        }
    }, [authenticated, nextClipsInfo])

    useEffect(() => {
        if (!currentClipsTopic && forMeData && !nextClipsInfo) {
            setCurrentClipsTopic(LABEL_FOR_ME);
            setNextClipsInfo(LABEL_FOR_ME, forMeData.clips);
        }
    }, [nextClipsInfo, forMeData, currentClipsTopic])

    const { state } = useLocation();
    useEffect(() => {
        if (state && state.closeAllModal) {
            setLoading(true);
            clipsContainerScrollReset();
            setCurrentClipIndex(0);
            setTimeout(() => {
                setLoading(false);
                if (state) {
                    state.closeAllModal = false;
                }
            }, 500)
        }
    }, [nextClipsInfo])

    const clipsContainerScrollReset = () => {
        const clipsScrollContainer = document.getElementById('clipsScrollContainer');
        if (clipsScrollContainer) {
            clipsScrollContainer.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }

    const playClip = (clip) => {
        setCurTime(0);
        setTimeRemaining(clip.totalTimeSeconds / audio.playbackRate);
        loadTrack(clip.mp3URL, 0, true);
    }

    const nextClip = () => {
        nextClipWithScrollBehavior("smooth");
    }

    const nextClipInstant = () => {
        nextClipWithScrollBehavior("instant");
    }

    const nextClipWithScrollBehavior = (behavior) => {
        const nextIndex = currentClipIndex + 1;
        if (nextClipsInfo && nextIndex < nextClipsInfo.clipsInfo.length) {
            const nextElementId = nextClipsInfo.clipsInfo[nextIndex].id;
            const elem = document.getElementById(nextElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: behavior, inline: "end" });
            }
        }
    }

    const prevClip = () => {
        prevClipWithBehavior("smooth");
    }

    const prevClipInstant = () => {
        prevClipWithBehavior("instant");
    }

    const prevClipWithBehavior = (behavior) => {
        
        let prevIndex = currentClipIndex - 1;
        if (nextClipsInfo && prevIndex >= 0 && prevIndex < nextClipsInfo.clipsInfo.length) {
            const prevElementId = nextClipsInfo.clipsInfo[prevIndex].id;
            const elem = document.getElementById(prevElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: behavior, inline: "end" });
            }
        }
    }

    const continueListening = () => {
        if (!track) {
            return;
        }

        const topicIds = [];
        for (let i = 0; i < track.relatedTopicClips.length; i++) {
            topicIds.push(track.relatedTopicClips[i].id);
        }

        eventLog(6, {
            'clipId': track.id,
            'speakerIds': track.titleSpeakerIds,
            'topicIds': topicIds,
        });
        logEvent(analytics, 'clip_card_continue_listening');
        loadTrack(track.trackMp3URL, track.clipStartTime + curTime);
        setShowAudioPlayerDrawer(true);
    }

    const [continuePercent, setContinuePercent] = useState(-1);

    useEffect(() => {
        let continueTimeout = null;
        if (track && currentClipIndex < nextClipsInfo.clipsInfo.length &&
            track.mp3URL === nextClipsInfo.clipsInfo[currentClipIndex].mp3URL) {
            if (continuePercent >= 100) {
                clearTimeout(continueTimeout);
                setContinuePercent(-1);
                setTimeRemaining(NaN);
                setTimeout(() => {
                    nextClipInstant();
                }, 500);
            } else if (continuePercent >= 0 || (!isNaN(timeRemaining) && timeRemaining === 0)) {
                continueTimeout = setTimeout(() => {
                    setContinuePercent(continuePercent + 1);
                },
                    // Use 3 seconds for coundown.
                    // 3000 milliseconds divided by 100. 
                    30);
            }
        } else {
            setContinuePercent(-1);
            clearTimeout(continueTimeout);
        }

        // effect cleanup
        return () => {
            clearTimeout(continueTimeout);
        }
    }, [continuePercent, timeRemaining, track])

    const playCurrentClip = () => {
        localStorage.setItem(TIP_PLAY_FIRST_CLIP, true);
        audio.play();
    }

    const playFirstClipButton = (
        <button onClick={ playCurrentClip } style={{
            padding: '8px',
            borderRadius: '8px',
            textAlign: 'left',
            width: '100%',
        }}>
            <table
                style={{ borderSpacing: 0, textAlign: 'left', width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ paddingRight: '12px' }}>
                            <PlayCircleRounded style={{ verticalAlign: 'middle', fontSize: '42px', color: ACCENT_COLOR }} />
                        </td>
                        <td style={{ width: '100%' }}>
                            <div style={{ fontSize: '16px', fontWeight: '600' }}>
                                Play Preview
                            </div>
                            <div className='sourceTitle'>
                                Generated with AI
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </button>
    )

    const clipFullEpisodePreview = (
        <div style={{
            padding: '8px',
            borderRadius: '8px',
            background: continuePercent === -1 ? '#FFFFFF81' : `linear-gradient(90deg, rgb(255, 255, 255), ${continuePercent}%, #5468ff21)`
        }}>
            {!track || track.mp3URL !== audio.src ?
                <table
                    style={{ borderSpacing: 0, textAlign: 'left', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: '12px' }}>
                                <div className='loading' style={{ height: '42px', width: '36px', borderRadius: '4px' }} />
                            </td>
                            <td style={{ width: '100%' }}>
                                <div className='loading' style={{ height: '42px', width: '100%', borderRadius: '4px' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <div>
                    <table style={{ width: '100%', borderSpacing: 0, textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: '12px' }}>
                                    <button onClick={continueListening}>
                                        <img alt='source' src={track.source.imageURL} className='sourceImage' />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ textAlign: 'left', width: '100%' }} onClick={continueListening}>
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                Go to Full Episode
                                            </div>
                                            <div className='sourceTitle'>
                                                {track.source.title}
                                            </div>
                                        </div>
                                    </button>
                                </td>
                                {authenticated ?
                                    <td style={{ minWidth: '40px', textAlign: 'right' }}>
                                        <BookmarkButton trackId={track.fullTrackId ?? track.id}
                                            buttonClassName='clipCardBookmarkButton'
                                            iconStyle={{ color: '#202124', fontSize: '30px' }} />
                                    </td>
                                    :
                                    <></>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )

    const homeClick = () => {
        audio.pause();
        navigate('/')
    }

    return (
        <div className="homeContainer menuBackground">

            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                trackId={track ? track.fullTrackId ?? track.id : null} />

            <div>
                {width < 600 ? <></> :
                    <div style={{ position: 'fixed', top: `${height / 2 - 56}px` }}>
                        <div style={{ display: 'inline-block', marginLeft: '-95px' }}>
                            <button disabled={currentClipIndex === 0} style={{ opacity: currentClipIndex === 0 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={prevClipInstant}>
                                <ChevronLeft style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: '600px' }}>
                            <button disabled={currentClipIndex ===  nextClipsInfo.clipsInfo.length - 1} style={{ opacity: currentClipIndex ===  nextClipsInfo.clipsInfo.length - 1 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={nextClipInstant}>
                                <ChevronRight style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                    </div>
                }

                <div className='main'>
                    <div id='clipsScrollContainer' className='hslider-container hideScrollbar horizontalScroll'>
                        {!nextClipsInfo || loading ?
                            <div><Loading /></div> :
                            <div>
                                {nextClipsInfo.clipsInfo.map((clip, index) => (
                                    <div key={clip.id} className='hslider-children' style={{ marginRight: index + 1 === nextClipsInfo.clipsInfo.length ? '30px' : '0px' }}>
                                        <ClipCard
                                            shouldLoadTrack={currentClipIndex + _NUM_CLIPS_TO_PREFETCH >= index}
                                            clip={clip}
                                            clipIndexInViewport={clipIndexInViewport}
                                            currentTime={curTime}
                                            index={index}
                                            playClip={playClip}
                                            nextClip={nextClip}
                                            playing={playing}
                                            timeRemaining={timeRemaining}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

                <div className='footerNoBlur'>
                    <div className='horizontalCenter'>
                        <div style={{ padding: '8px' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <div className='bottomBar' style={{ width: '100%' }}>
                                                {!localStorage.TIP_PLAY_FIRST_CLIP && !playing ? playFirstClipButton : clipFullEpisodePreview}
                                            </div>
                                        </td>
                                        <td style={{ paddingLeft: '8px', paddingRight: '22px' }}>

                                            <button onClick={homeClick} className='bottomBar' style={{ height: '58px', width: '58px' }}>
                                                <HomeRounded style={{ verticalAlign: 'middle', fontSize: '30px' }} />
                                                {/* <img src='/icon.png' style={{ verticalAlign: 'middle', width: '24px' }} /> */}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentClipsTopic: state.data.currentClipsTopic,
    nextClipsInfo: state.data.nextClipsInfo,
    track: state.data.track,

    authenticated: state.user.authenticated,
    forMeData: state.user.forMeData,
    forMeLoading: state.user.forMeLoading,
})

const mapActionsToProps = {
    loadForMe,
    setCurrentClipsTopic,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(Clips);