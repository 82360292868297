import { getAnalytics, logEvent } from 'firebase/analytics';

const ExampleSpeakerTopicIcons = ({ searchQuery, showPreview }) => {
    const analytics = getAnalytics();

    const emojiTopics = [
        ["🎵", "music", "wiki_5422144", "Taylor Swift"],
        ["😂", "comedy writing", "wiki_104492", "Jerry Seinfeld"],
        ["🎥", "filmmaking", "wiki_26572102", "Greta Gerwig"],
        ["🎭", "acting", "wiki_29791561", "Steven Yeun"],
        ["📺", "reality tv", "wiki_19394613", "Kim Kardashian"],
        ["🕶️", "fashion", "wiki_730578", "Anna Wintour"],
    ]

    const emojiTopics2 = [
        ["🏀", "nba", "wiki_246185", "Kobe Bryant"],
        ["🏈", "football", "wiki_339841", "Tom Brady"],
        ["⛳", "golf", "wiki_242911", "Jack Nicklaus"],
        ["🎾", "tennis", "wiki_164910", "Serena Williams"],
        ["🥊", "boxing", "wiki_39027", "Mike Tyson"],
        ["🤼", "mma", "wiki_38738224", "Conor McGregor"],
    ]

    const emojiTopics3 = [
        ["🏎️", "racing", "wiki_675561", "Lewis Hamilton"],
        ["🧗", "climbing", "wiki_29469916", "Alex Honnold"],
        ["🏃", "marathons", "wiki_1112610", "Meb Keflezighi"],
        ["🚴", "cycling", "wiki_23243880", "Lance Armstrong"],
        ["♟️", "chess", "wiki_12810", "Garry Kasparov"],
        ["💰", "business", "wiki_909036", "Elon Musk"],
    ]

    const emojiTopics4 = [
        ["🤖", "machine learning", "wiki_3259263", "Demis Hassabis"],
        ["👶", "parenting", "wiki_4607628", "Emily Oster"],
        ["🐒", "animals", "wiki_45397", "Jane Goodall"],
        ["🍳", "cooking", "wiki_942637", "Thomas Keller"],
        ["🦄", "startups", "wiki_22476815", "Sam Altman"],
        ["🧠", "neuroscience", "wiki_51277767", "Andrew Huberman"],
    ]

    const exampleSpeakerClick = (speakerName) => () => {
        logEvent(analytics, 'example_speaker_click');
        searchQuery(speakerName);
    }

    const exampleTopicClick = (topicName) => () => {
        logEvent(analytics, 'example_topic_click');
        searchQuery(topicName);
    }

    return (
        <div>
            <div style={{ padding: '16px 0px', textAlign: 'left' }}>
                <div>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                {emojiTopics.map((emojiTopic, i) => (
                                    <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                        <button className='exampleTopicEmoji' onClick={exampleSpeakerClick(emojiTopic[3])}>
                                            <img className='exampleSpeakerImage' src={`https://firebasestorage.googleapis.com/v0/b/twinklepoo/o/photos%2F${emojiTopic[2]}.png?alt=media`} />
                                        </button>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                {emojiTopics.map((emojiTopic, i) => (
                                    <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                        <button className='exampleTopicEmoji' onClick={exampleTopicClick(emojiTopic[1])}>
                                            <span>
                                                {emojiTopic[0]}
                                            </span>
                                        </button>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>

                    {showPreview ? <></> :
                        <div>
                            <table style={{ paddingTop: '16px', marginTop: '16px', borderTop: '1px solid rgb(242, 242, 242)', width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {emojiTopics2.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleSpeakerClick(emojiTopic[3])}>
                                                    <img className='exampleSpeakerImage' src={`https://firebasestorage.googleapis.com/v0/b/twinklepoo/o/photos%2F${emojiTopic[2]}.png?alt=media`} />
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {emojiTopics2.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleTopicClick(emojiTopic[1])}>
                                                    <span>
                                                        {emojiTopic[0]}
                                                    </span>
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>

                            <table style={{ paddingTop: '16px', marginTop: '16px', borderTop: '1px solid rgb(242, 242, 242)', width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {emojiTopics3.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleSpeakerClick(emojiTopic[3])}>
                                                    <img className='exampleSpeakerImage' src={`https://firebasestorage.googleapis.com/v0/b/twinklepoo/o/photos%2F${emojiTopic[2]}.png?alt=media`} />
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {emojiTopics3.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleTopicClick(emojiTopic[1])}>
                                                    <span>
                                                        {emojiTopic[0]}
                                                    </span>
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>


                            <table style={{ paddingTop: '16px', marginTop: '16px', borderTop: '1px solid rgb(242, 242, 242)', width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {emojiTopics4.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleSpeakerClick(emojiTopic[3])}>
                                                    <img className='exampleSpeakerImage' src={`https://firebasestorage.googleapis.com/v0/b/twinklepoo/o/photos%2F${emojiTopic[2]}.png?alt=media`} />
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {emojiTopics4.map((emojiTopic, i) => (
                                            <td key={i} style={{ width: `${100 / emojiTopics.length}%` }}>
                                                <button className='exampleTopicEmoji' onClick={exampleTopicClick(emojiTopic[1])}>
                                                    <span>
                                                        {emojiTopic[0]}
                                                    </span>
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>                        
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ExampleSpeakerTopicIcons;
