import { connect } from 'react-redux';

import AudioPlayer from '../components/AudioPlayer'

import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import { getAnalytics, logEvent } from "firebase/analytics";


const Track = ({
}) => {
  let params = useParams();
  const analytics = getAnalytics();

  const [trackId, setTrackId] = useState(null);

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'track'
    });
  }, []);

  useEffect(() => {
    if (!!params.trackId) {
      setTrackId(params.trackId);
    }
  }, [params])

  return (
    !!trackId ?
      <AudioPlayer trackId={trackId} /> :
      <div>missing track id</div>
  );
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Track);