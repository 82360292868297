export const getSectionTopicNames = (topics) => {
    if (!topics) {
        return '';
    }
    const topicNames = [];
    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        const name = topic.entity.name;
        topicNames.push(name);
    }
    return topicNames
}

export const getSectionTitle = (section) => {
    if (section.title) {
        return section.title;
    }
    const topicNames = getSectionTopicNames(section.topTopics);
    if (topicNames.length > 0) {
        return topicNames[0];
    }
    return '( missing title )';
}