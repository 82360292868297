import { getAnalytics, logEvent } from "firebase/analytics";
import { shuffle } from 'lodash';
import { eventLog } from './eventHelper';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import { setCurrentClipsTopic, setNextClipsInfo } from "../redux/actions/dataActions";
import { setSubscribeStatus } from '../redux/actions/userActions';
import ProfilePicture from "./ProfilePicture";
import axios from "axios";
import { useState } from "react";
import { WebStoriesOutlined } from "@mui/icons-material";
import { loadTrack } from "./audioHelper";


const SpeakerHeader = ({
    speaker,
    clips,
    onSpeakerClick,
    // Redux.
    authenticated,
    setCurrentClipsTopic,
    setNextClipsInfo,
    setSubscribeStatus,
    subscribeIds,

}) => {

    const analytics = getAnalytics();
    const navigate = useNavigate();

    const subscribeClick = () => {
        if (!authenticated) {
            return;
        }
        logEvent(analytics, !currentSubscribeStatus ? 'subscribe_true' : 'subscribe_false');
        setSubscribeStatus(speaker.id, !currentSubscribeStatus);
    }

    const isSubscribed = () => {
        const subscribeIdSet = new Set(subscribeIds);
        return subscribeIdSet.has(speaker.id);
    }

    const currentSubscribeStatus = isSubscribed();

    const [loadingClips, setLoadingClips] = useState(false);

    const playClipsClick = () => {
        if (clips && clips.length > 0) {
            const shuffledClips = shuffle(clips);
            playClips(shuffledClips)
        } else {
            setLoadingClips(true);
            axios
                .get(`/speaker/${encodeURIComponent(speaker.id)}`)
                .then((res) => {
                    setLoadingClips(false);
                    if (res.data && res.data.clips && res.data.clips.length > 0) {
                        const shuffledClips = shuffle(res.data.clips);
                        playClips(shuffledClips)
                    } else {
                        window.alert('No previews found.');
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const playClips = (clipsToPlay) => {
        const name = speaker.name;
        loadTrack(clipsToPlay[0].mp3URL, 0)
        setCurrentClipsTopic(name);
        setNextClipsInfo(name, clipsToPlay);
        document.getElementById('audio').pause();
        navigate('/clips', { state: { closeAllModal: true } });

        eventLog(1, {
            'speakerId': speaker.id
        });
    }

    return (
        <div>
            <div style={{ padding: '16px 20px 6px' }}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ minWidth: '96px' }}>
                                <ProfilePicture
                                    imageSrc={speaker.imageURL}
                                    color={'rgb(210, 210, 210)'}
                                    name={speaker.name}
                                    profileClick={onSpeakerClick ? onSpeakerClick : subscribeClick}
                                    width='78px'
                                    height='78px'
                                    margin='-3px 0px 0px 0px'
                                />
                            </td>

                            <td style={{ width: '100%' }}>
                                <button onClick={onSpeakerClick ? onSpeakerClick : subscribeClick} style={{ textAlign: 'left' }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <div className='speakerNameLarge'>
                                            {speaker.name}
                                        </div>
                                        <div className='speakerDescriptionTwoLines' style={{ fontSize: '16px' }}>
                                            {speaker.description}
                                        </div>
                                    </div>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table style={{ padding: '4px 16px 0px', width: '100%' }}>
                <tbody>
                    <tr>
                        {!authenticated ? <></> :
                            <td style={{ width: '50%', padding: '0px 4px' }}>
                                <button className='entityInfoTopButton' onClick={subscribeClick}>
                                    <img alt='subscribe' src={currentSubscribeStatus ? '/subscribe_fill.png' : '/subscribe_outline.png'} style={{ width: '24px', verticalAlign: 'middle', marginRight: '12px' }} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {currentSubscribeStatus ? 'Subscribed' : 'Subscribe'}
                                    </span>
                                </button>
                            </td>
                        }
                        <td style={{ width: '50%', padding: '0px 4px' }}>
                            {loadingClips ?
                                <button className='entityInfoTopButton'>
                                    <span className='glow' style={{ color: 'rgb(80, 80, 80)', fontSize: '16px' }}>Loading...</span>
                                </button>
                                :
                                <button className='entityInfoTopButton' onClick={playClipsClick}>
                                    <WebStoriesOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle', margin: '0px 3px 0px 12px' }}>
                                        Play Clip
                                    </span>
                                </button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    subscribeIds: state.user.subscribeIds,
})

const mapActionsToProps = {
    setCurrentClipsTopic,
    setNextClipsInfo,
    setSubscribeStatus,
}

export default connect(mapStateToProps, mapActionsToProps)(SpeakerHeader);

