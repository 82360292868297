
import { PlayCircleFilled } from "@material-ui/icons";
import { getAnalytics } from "firebase/analytics";
import BookmarkButton from "./BookmarkButton";
import TrackOverviewSpeaker from "./TrackOverviewSpeaker";
import { fancyTimeFormat, fancyTimeFormatWithHM } from './timeHelper';
import { useRef } from "react";


export default function TrackInfoRow(props) {
    const { trackInfo, playTrack, hideBottomBorder } = props;
    const analytics = getAnalytics();

    const tableRef = useRef(null);

    return (
        <div>
            <div>
                <table ref={tableRef} style={{ width: '100%', textAlign: 'left', padding: '20px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '98px' }}>
                                <button onClick={playTrack(trackInfo.id, trackInfo.mp3URL, trackInfo.lastListenSeconds || 0)}>
                                    <img src={trackInfo.source.imageURL} className='trackInfoSourceImage' />
                                </button>
                            </td>
                            <td style={{ maxWidth: tableRef.current ? `${tableRef.current.offsetWidth - 144}px` : '200px' }}>
                                <button onClick={playTrack(trackInfo.id, trackInfo.mp3URL, trackInfo.lastListenSeconds || 0)} style={{ textAlign: 'left', width: '100%' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div className='trackInfoSourceTitle'>
                                            {trackInfo.source.title}
                                        </div>
                                    </div>
                                </button>
                                <div style={{ marginTop: '8px' }}>
                                    <button onClick={playTrack(trackInfo.id, trackInfo.mp3URL, trackInfo.lastListenSeconds || 0)} className=''>
                                        <PlayCircleFilled style={{
                                            verticalAlign: 'middle',
                                            fontSize: '30px',
                                            marginTop: '0px',
                                        }} />
                                        <span style={{ fontSize: '14px', verticalAlign: 'middle', paddingLeft: '8px' }}>
                                            {trackInfo.lastListenSeconds ?
                                                trackInfo.totalTimeSeconds - trackInfo.lastListenSeconds < 120 ?
                                                    <span style={{ fontWeight: '600' }}>
                                                        FINISHED
                                                    </span> :
                                                    `-${fancyTimeFormat(trackInfo.totalTimeSeconds - trackInfo.lastListenSeconds)}`
                                                :
                                                `${fancyTimeFormatWithHM(trackInfo.totalTimeSeconds)}`
                                            }
                                        </span>
                                    </button>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <BookmarkButton trackId={trackInfo.id}
                                            buttonClassName=''
                                            iconStyle={{
                                                verticalAlign: 'middle',
                                                fontSize: '30px',
                                                marginRight: '5px'
                                            }} />
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ paddingBottom: '20px' }}>
                <div style={{ padding: '0px 20px 0px 38px' }}>
                    {
                        trackInfo.speakers.map((speaker, speakerIndex) => (
                            <TrackOverviewSpeaker
                                key={speakerIndex}
                                speaker={speaker}
                                speakerColor={'rgb(210, 210, 210)'}
                            />
                        ))
                    }
                </div>
            </div>
            {hideBottomBorder ? <></> : <div className='thinRightDivider' style={{ marginBottom: '10px' }} />}
        </div>
    );
}
