import Highlighter from 'react-highlight-words';

const TopicButton = ({ name, query, selectedSearchTopic }) => {
    return (
        <div className='firstTopicContainer'>
            <button className='firstTopicButton' onClick={selectedSearchTopic(name)}>
                <Highlighter
                    highlightClassName="ais-Highlight-highlighted"
                    unhighlightClassName="ais-Highlight-nonHighlighted"
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={name}
                />
            </button>
        </div>
    );
};

export default TopicButton;