const ClipInfoLoading = ({ marginBottom }) => {
    return (
        <table style={{ marginBottom: marginBottom, width: '100%'}}>
            <tbody>
                <tr>
                    <td style={{ width: '98px' }}>
                        <div className='loading' style={{ marginRight: '20px', height: '78px', width: '78px', borderRadius: '50%' }} />
                    </td>
                    <td>
                        <div className='loading' style={{ width: '100%', height: '40px', margin: '5px 0px', borderRadius: '5px' }}></div>
                        <div className='loading' style={{ width: '100%', height: '30px', margin: '5px 0px', borderRadius: '5px' }}></div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

ClipInfoLoading.defaultProps = {
    marginBottom: '',
}

ClipInfoLoading.propTypes = {

}

export default ClipInfoLoading;