import { forwardRef, useState } from "react";

import { getAnalytics, logEvent } from "firebase/analytics";

import ButtonTipPopup from "./ButtonTipPopup";

import Modal from '@mui/material/Modal';
import { IosShareRounded, LinkRounded } from "@mui/icons-material";

import { LABEL_APPNAME, LABEL_DOMAIN } from "../constants/labels";
import { eventClipShared } from "./eventHelper";
import { isIPhone } from "./deviceHelper";


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));

const ClipShareButton = ({ buttonStyle, iconStyle, trackId, messageIndex, title, summary, clipLength }) => {
    const analytics = getAnalytics();

    const [showCopied, setShowCopied] = useState(false);

    const toggleShowCopied = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowCopied(open);
    }

    const shareClip = () => {
        const link = `https:/${LABEL_DOMAIN}/clip/${trackId}/${messageIndex}`;
        logEvent(analytics, "share_clip_card");
        if (navigator.share && isIPhone()) {
            if (!clipLength) {
                clipLength = 0
            }
            const clipMinutes = Math.floor(Math.max(60, clipLength) / 60)
            const clipLengthText = `${clipMinutes} min`
            const shareText = 'Thought you might like this ' + clipLengthText + ' clip:\n\n🎙️ ' + title + '\n\n' + summary + '\n\n' + link;
            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => { })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            navigator.clipboard.writeText(link);
            setShowCopied(true);
        }
        eventClipShared(`${trackId}_${messageIndex}`);
    }

    return (
        <button style={buttonStyle} onClick={shareClip}>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
                // onClose={toggleShowCopied(false)}
            >
                <ButtonTipPopupRef close={toggleShowCopied(false)} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>

            <IosShareRounded style={iconStyle} />
        </button>
    );
};

export default ClipShareButton;
