import { forwardRef, useEffect, useRef, useState } from 'react';

// Redux.
import { connect } from 'react-redux';

import { ContentCopyRounded, IosShareRounded } from '@mui/icons-material';
import { Link, SwapVert } from '@material-ui/icons';

// MUI components.
import Modal from '@mui/material/Modal';

// Components.
import ButtonTipPopup from './ButtonTipPopup';
import CloseFooter from './CloseFooter';
import TestFlight from './TestFlight';

import { getSectionTitle } from './sectionHelper';
import { fancyTimeFormat } from './timeHelper';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { LABEL_APPNAME, LABEL_DOMAIN } from '../constants/labels';
import { isIPhone } from './deviceHelper';


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));

const Sections = ({
    close,
    curTime,
    currentSection,
    sectionsContainerRef,
    track,
}) => {
    const audio = document.getElementById('audio');
    const analytics = getAnalytics();

    const [showCurrentSectionButton, setShowCurrentSectionButton] = useState(true);
    const [showCopiedLink, setShowCopiedLink] = useState(false);
    const [currentSectionTimeLeft, setCurrentSectionTimeLeft] = useState('');
    const scrollIntoViewInProgress = useRef(false);
    const scrollTimeout = useRef(null);

    const headingHeight = '88px';

    useEffect(() => {
        if (!showCurrentSectionButton && !scrollIntoViewInProgress.current) {
            const elem = document.getElementById(getSectionId(currentSection));
            if (elem) {
                scrollIntoViewInProgress.current = true;
                elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 500);
            } else {
                // console.log("missing id: " + elementId);
            }
        }
    }, [currentSection, showCurrentSectionButton])

    useEffect(() => {
        let sectionTimeEnd = track.totalTimeSeconds;
        if (currentSection >= 0 && currentSection + 1 < track.sectionInfos.length) {
            sectionTimeEnd = track.sectionInfos[currentSection + 1].startTime;
        }
        const timeRemaining = Math.max(0, sectionTimeEnd - curTime);
        setCurrentSectionTimeLeft(`-${fancyTimeFormat(timeRemaining / audio.playbackRate)}`)
    }, [curTime, currentSection])

    useEffect(() => {
        const onScroll = (e) => {
            clearTimeout(scrollTimeout.current);
            // Set in progress to false once scroll finishes.
            // onScroll will continue updating scrollTimeout when scroll is in progress.
            // when scroll finishes, scrollIntoViewInProgress will be set to false.
            if (scrollIntoViewInProgress.current) {
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 500);
            } else {
                // If user manually scrolls, disable auto scrolling to current section.
                setShowCurrentSectionButton(true);
            }
        }

        // DOM listeners: update React state on DOM events
        // document.addEventListener('scroll', onScroll);
        sectionsContainerRef.current.addEventListener('scroll', onScroll);

        // effect cleanup
        return () => {
            // clearTimeout(scrollTimeout.current);
            // window.removeEventListener('scroll', onScroll);
            const sectionsContainer = sectionsContainerRef.current;
            if (sectionsContainer) {
                sectionsContainer.removeEventListener('scroll', onScroll);
            }
        }
    }, []);

    const seek = (timeSeconds) => {
        audio.currentTime = timeSeconds;
        audio.play().catch(function () { });
    }

    const getSectionSummary = (section) => {
        if (section.summary) {
            return section.summary;
        }
        return '';
    }

    const getSectionId = (sectionIndex) => {
        return `${track.fullTrackId ?? track.id}_section_${sectionIndex + 1}`
    }

    const shareSection = (sectionIndex) => () => {
        const section = track.sectionInfos[sectionIndex];
        const link = `https://${LABEL_DOMAIN}/track/${track.fullTrackId ?? track.id}?start=${Math.floor(section.startTime)}`;
        if (navigator.share && isIPhone()) {
            let shareText = 'Thought you might like this section:\n\n🎙️ ' + section.title + '\n\n' + section.summary + '\n\n' + link;
            logEvent(analytics, 'section_share');
            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => { })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            setShowCopiedLink(true);
            navigator.clipboard.writeText(link);
        }
    }

    return (
        <div className='hasCloseFooter' style={{ textAlign: 'left', width: '100%' }}>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopiedLink}
            >
                <ButtonTipPopupRef
                    close={() => { setShowCopiedLink(false) }}
                    content={(
                        <div>
                            <div>
                                <ContentCopyRounded style={{ fontSize: '80px' }} />
                            </div>
                            <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                                copied link
                            </div>
                        </div>
                    )} />
            </Modal>

            {!track ? <></> :
                <div>
                    <div>
                        <div className='sectionsHeading'>
                            <div className='horizontalCenter' style={{ padding: '20px'}}>
                                <span className='sectionAndTranscriptHeading'>Sections</span>
                                <div className='subheading' style={{ fontSize: '14px' }}>
                                    Generated with AI
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingTop: headingHeight, paddingBottom: '100px' }}>
                        <div style={{ textAlign: 'center', padding: '20px', fontWeight: '500', borderBottom: '1px solid rgb(242, 242, 242)', marginBottom: '10px' }}>
                            <TestFlight />
                        </div>

                        {track.sectionInfos.map((section, sectionIndex) => (
                            <div id={getSectionId(sectionIndex)} key={sectionIndex} style={{
                                borderBottom: '1px solid rgb(234, 234, 234)',
                                marginLeft: '20px',
                                // leaving space at top for header for current section autoscroll.
                                paddingTop: headingHeight,
                                marginTop: `-${headingHeight}`
                                // background: isCurrentSection(curTime, sectionIndex) ? 'linear-gradient(224deg, rgb(255, 240, 255) 0%, rgb(240, 255, 255) 100%)' : 'white'
                            }}>
                                <div style={{ padding: '16px 0px' }}>
                                    <div style={{ padding: '0px 20px 0px 0px', width: '100%' }}>
                                        <button style={{
                                            textAlign: 'left', width: '100%'
                                        }} onClick={() => { seek(section.startTime) }} >
                                            <div style={{
                                                color: 'rgb(32, 33, 36)',
                                                width: '100%',
                                                fontWeight: '500',
                                                fontSize: '16px', textAlign: 'left'
                                            }}>
                                                <span style={{ display: 'inline-block', color: 'rgb(80, 80, 80)', fontWeight: '500', paddingRight: '16px' }}>{sectionIndex + 1}</span>
                                                <span>{getSectionTitle(section)}</span>
                                            </div>

                                            <div style={{ marginTop: '4px', width: '100%', fontSize: '15px' }}>
                                                <span style={{ color: 'rgb(80, 80, 80)' }}>
                                                    {getSectionSummary(section)}
                                                </span>
                                            </div>
                                        </button>
                                        <table style={{ marginTop: '10px', width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '100%' }}>
                                                        {sectionIndex !== currentSection ? <></> :
                                                            <span style={{ display: 'inline-block', textAlign: 'center', width: '100%', background: 'rgb(242, 242, 242)', borderRadius: '8px', padding: '10px', fontSize: '14px', fontWeight: '400' }}>
                                                                {/* <span style={{ fontSize: '15px', fontWeight: '400', verticalAlign: 'middle', }}>current</span> */}
                                                                {/* <img src='/sound.gif' style={{ width: '18px', verticalAlign: 'middle', float: 'left' }} /> */}
                                                                <span style={{ verticalAlign: 'middle', paddingRight: '8px' }}>
                                                                    ⌛
                                                                </span>
                                                                <span style={{ color: 'rgb(80, 80, 80)', verticalAlign: 'middle' }}>
                                                                    {currentSectionTimeLeft}
                                                                </span>
                                                            </span>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth: '100px' }}>
                                                        <button onClick={shareSection(sectionIndex)} style={{ color: 'rgb(80, 80, 80)', padding: '0px 10px', float: 'right' }}>
                                                            {navigator.share ?
                                                                <IosShareRounded style={{ marginRight: '8px', fontSize: '20px', padding: '2px', verticalAlign: 'middle' }} />
                                                                :
                                                                <Link style={{ marginRight: '8px', fontSize: '20px', verticalAlign: 'middle' }} />
                                                            }

                                                            <span style={{ verticalAlign: 'middle', display: 'inline-block', marginTop: navigator.share ? '3px' : '1px', fontSize: '14px' }}>
                                                                {fancyTimeFormat(track.sectionInfos[sectionIndex].startTime / audio.playbackRate)}
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <CloseFooter onClick={() => { setShowSectionOverview(false); }} /> */}

                    {showCurrentSectionButton ?
                        <div className='syncSectionContainer'>
                            <button className='syncButton' onClick={() => { setShowCurrentSectionButton(false) }}>
                                <div className='syncButtonDiv'>
                                    <SwapVert style={{ marginLeft: '8px', fontSize: '22px', verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle', padding: '0px 16px 0px 8px' }}>
                                        Auto Scroll
                                    </span>
                                </div>
                            </button>
                        </div>
                        :
                        <></>
                    }

                    <CloseFooter onClick={close} />
                </div>
            }
        </div>
    );
}

Sections.propTypes = {

}

Sections.defaultProps = {

}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Sections);
