import { SwapVert } from "@material-ui/icons";

export default function SyncTranscriptAudio({
    bottom,
    currentTime,
    scrollToCurrentMessage,
    setScrollToCurrentMessage,
    track,
}) {
    const audio = document.getElementById('audio');

    return (
        <div className='syncButtonContainerBottom' style={
            !track || scrollToCurrentMessage || currentTime === 0 || (audio && audio.duration === audio.currentTime) ? {
                // Hide by moving above.
                // Using visibility and display to hide causes scrollIntoView to be interrupted in Chrome.
                position: 'fixed',
                top: '-5000px'
            } : {
                bottom: bottom,
            }
        }>
            <button className='syncButton'
                onClick={() => {
                    setScrollToCurrentMessage(true);
                }}>
                <div className='syncButtonDiv'>
                    <div>
                        <SwapVert style={{ marginLeft: '10px', fontSize: '22px', verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle', padding: '0px 16px 0px 8px' }}>Auto Scroll</span>
                    </div>
                </div>
            </button>
        </div>
    );
}

