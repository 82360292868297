import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Scroll smoothly.
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

// Firebase.
// import firebase from 'firebase/compat/app';
// import { FIREBASE_CONFIG } from './firebaseHelper'
// firebase.initializeApp(FIREBASE_CONFIG);
import { auth } from './firebaseHelper'

const a = auth;

// Init scroll smoothly.
elementScrollIntoViewPolyfill();

// TODO?
reportWebVitals();

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

