import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setSearchText } from '../redux/actions/dataActions';

import { useEffect, useRef, useState } from 'react'

import Message from './Message'
import Loading from './Loading'

import {
    getSpeakerData,
} from './speakerHelper'

import { speakerColor } from "../constants/colors"

import { getAnalytics } from "firebase/analytics";
import { getSectionTitle } from './sectionHelper';
import useWindowDimensions from './windowHelper';
import { loadTrack } from './audioHelper';

const Transcript = ({
    // From redux.
    authenticated,
    setSearchText,
    track,

    // Passed in.
    autoscrollPaddingTop,
    bottomContent,
    curTime,
    currentSection,
    isClip,
    topContent,
    openSpeakerInfo,
    pathname,
    playing,
    scrollToCurrentMessage,
    setScrollToCurrentMessage,
    transcriptContainerRef,
}) => {

    const [message, setMessage] = useState({});
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const scrollIntoViewInProgress = useRef(null);
    const oldScroll = useRef(0);
    const scrollTimeout = useRef(null);

    const { height } = useWindowDimensions();

    const audio = document.getElementById('audio');

    const reset = () => {
        transcriptContainerRef.current.scrollTo(0, 0)
        setMessage({});
        setSearchText('');
    }

    const analytics = getAnalytics();

    const setScrollToCurrentMessageWrapper = (value) => {
        setShowScrollTopButton(false);
        setScrollToCurrentMessage(value);
    }

    const scrollToTop = () => {
        scrollIntoViewHelper('transcriptTop');
        setShowScrollTopButton(false);
    }

    useEffect(() => {
        const onScroll = (e) => {
            clearTimeout(scrollTimeout.current);
            // Set in progress to false once scroll finishes.
            // onScroll will continue updating scrollTimeout when scroll is in progress.
            // when scroll finishes, scrollIntoViewInProgress will be set to false.
            if (scrollIntoViewInProgress.current) {
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 300);
            } else {
                // If user manually scrolls, disable auto scrolling to current message.
                setScrollToCurrentMessage(false);
            }

            if (!isClip) {
                if (!scrollIntoViewInProgress.current) {
                    var st = transcriptContainerRef.current.scrollTop;
                    if (st > oldScroll.current) {
                        // downscroll code
                        setShowScrollTopButton(false);
                    } else if (st < oldScroll.current) {
                        // scroll up
                        if (st > 200) {
                            setShowScrollTopButton(true);
                        } else {
                            setShowScrollTopButton(false);
                        }
                    }
                    oldScroll.current = st <= 0 ? 0 : st
                } else {
                    setShowScrollTopButton(false);
                }
            }
        }

        // DOM listeners: update React state on DOM events
        // window.addEventListener('scroll', onScroll);
        transcriptContainerRef.current.addEventListener('scroll', onScroll);

        // effect cleanup
        return () => {
            // clearTimeout(scrollTimeout.current);
            // window.removeEventListener('scroll', onScroll);
            const transcriptContainer = transcriptContainerRef.current;
            if (transcriptContainer) {
                transcriptContainer.removeEventListener('scroll', onScroll);
            }
        }
    });

    const bottomId = 'transcriptBottom';

    const scrollIntoViewHelper = (elementId) => {
        let elem = document.getElementById(elementId);
        if (isClip && track && track.transcript && track.transcript[0].id === elementId) {
            elem = document.getElementById('transcriptTop');
        }
        if (elem) {
            scrollIntoViewInProgress.current = true;
            elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
            scrollTimeout.current = setTimeout(function () {
                scrollIntoViewInProgress.current = false;
            }, 400);
        } else {
            // console.log("missing id: " + elementId);
        }
    }

    // Scroll to current message when audio time changes.
    useEffect(() => {
        if (track && !scrollIntoViewInProgress.current) {
            const messages = track.transcript;
            for (var i = messages.length - 1; i >= 0; i--) {
                var m = messages[i]
                if (m.timeSeconds < curTime || i === 0) {
                    const shouldScroll = scrollToCurrentMessage && m.id !== message.id;
                    if (shouldScroll) {
                        scrollIntoViewHelper(m.id);
                    }
                    setMessage(m);
                    break;
                }
            }
        }
    }, [curTime, track])

    // Scroll to bottom when audio finishes.
    useEffect(() => {
        if (!playing && curTime === audio.duration) {
            scrollIntoViewHelper(bottomId);
        }
    }, [curTime, playing])

    // Scroll to current message when button is clicked.
    useEffect(() => {
        if (scrollToCurrentMessage) {
            if (message && message.id) {
                scrollIntoViewHelper(message.id);
            }
        }
    }, [scrollToCurrentMessage])

    const seekFn = (timeSeconds) => () => {
        setMessage({});
        if (audio.src !== track.mp3URL) {
            loadTrack(track.mp3URL, timeSeconds)
            audio.play();
        } else {
            seek(timeSeconds);
        }
    }

    const seek = (timeSeconds) => {
        setScrollToCurrentMessageWrapper(true);
        audio.play().catch(function () { });
        audio.currentTime = timeSeconds;
    }

    function isNewSpeaker(index, messages) {
        if (index === 0) {
            return true;
        }
        return messages[index - 1].speakerId !== messages[index].speakerId;
    }

    const messagesForTrack = (track, start, numMessages) => {
        const messages = [];
        for (let i = start; i < start + numMessages; i++) {
            const message = track.transcript[i];
            messages.push(message);
        }
        return messages;
    }


    return (
        <div style={{ width: '100%' }}>
            <div id="transcriptTop"></div>
            <div>
                {!track || !pathname || track.pathname !== pathname ?
                    <div>
                        <Loading text={isClip ? "Loading Clip" : "Loading Episode"} subText="Generating transcript" />
                    </div>
                    :
                    <div>
                        {topContent}
                        <div style={{ paddingBottom: isClip ? '20px' : '100px' }}>
                            {track.sectionInfos.map((section, sectionIndex) => (
                                <div key={sectionIndex}>
                                    {track.sectionInfos.length < 2 ? <></> :
                                        <div style={{
                                            opacity: !scrollToCurrentMessage || currentSection === sectionIndex || !playing ? 1.0 : 0.55,
                                        }}>
                                            <div className='section'>
                                                <button style={{ textAlign: 'left' }} onClick={seekFn(section.startTime)}>
                                                    <div className='sectionTitleInTranscript'>
                                                        {getSectionTitle(section)}
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'rgb(80, 80, 80)', fontSize: '14px', verticalAlign: 'middle' }}>Section {sectionIndex + 1} of {track.sectionInfos.length}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        messagesForTrack(track, section.startIndex, section.numMessages).map((m, index) => (
                                            <div key={m.id}>
                                                {false ? <div id={m.id}></div> :
                                                    <div id={m.id} style={{
                                                        // add padding/margin so autoscroll doesn't scroll all the
                                                        // way to the top and leaves some buffer.
                                                        // e.g when clicking next/prev section button.
                                                        paddingTop: `${autoscrollPaddingTop}px`,
                                                        marginTop: `-${autoscrollPaddingTop}px`,
                                                    }}>
                                                        {playing && m.id === message.id && curTime > 0 ?
                                                            <Message
                                                                authenticated={authenticated}
                                                                message={m}
                                                                isCurrent={true}
                                                                curTime={curTime}
                                                                openSpeakerInfo={openSpeakerInfo}
                                                                playing={playing}
                                                                scrollToCurrentMessage={scrollToCurrentMessage}
                                                                setScrollToCurrentMessage={setScrollToCurrentMessageWrapper}
                                                                seekFn={seekFn}
                                                                borderColor={speakerColor(m.speakerId, track.speakers)}
                                                                speaker={getSpeakerData(m.speakerId, track.speakers)}
                                                                showSpeakerDetails={isNewSpeaker(index, messagesForTrack(track, section.startIndex, section.numMessages))}
                                                                isLastMessage={index + 1 === section.numMessages}
                                                            />
                                                            :
                                                            // Same as above but don't refer to curTime to allow for faster updates.
                                                            <Message
                                                                authenticated={authenticated}
                                                                message={m}
                                                                isCurrent={false}
                                                                openSpeakerInfo={openSpeakerInfo}
                                                                playing={playing}
                                                                scrollToCurrentMessage={scrollToCurrentMessage}
                                                                setScrollToCurrentMessage={setScrollToCurrentMessageWrapper}
                                                                seekFn={seekFn}
                                                                borderColor={speakerColor(m.speakerId, track.speakers)}
                                                                speaker={getSpeakerData(m.speakerId, track.speakers)}
                                                                showSpeakerDetails={isNewSpeaker(index, messagesForTrack(track, section.startIndex, section.numMessages))}
                                                                isLastMessage={index + 1 === section.numMessages}
                                                            />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))}
                            <div id={bottomId}>
                                {bottomContent}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div style={{ display: showScrollTopButton ? 'block' : 'none', width: '100%', maxWidth: '540px', textAlign: 'right', position: 'fixed', top: '0' }}>
                <button className='blurBackground' style={{ boxShadow: '0 0px 6px rgba(0, 0, 0, 0.14)', fontSize: '24px', width: '44px', height: '44px', borderRadius: '50%', margin: '12px 12px' }} onClick={scrollToTop}>
                    🔝
                </button>
            </div>
        </div >
    );
};

Transcript.defaultProps = {

}

Transcript.propTypes = {
    openSpeakerInfo: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    track: state.data.track,
})

const mapActionsToProps = {
    setSearchText,
}

export default connect(mapStateToProps, mapActionsToProps)(Transcript);