import axios from 'axios';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useEffect, useState } from 'react'

import { getAnalytics } from "firebase/analytics";

import Loading from './Loading';
import { loadTrack } from './audioHelper'

import { validTokenExists } from '../firebaseHelper';
import { } from '../redux/actions/userActions';


import { Refresh, VolumeUpRounded } from '@material-ui/icons';
import TrackInfoRow from './TrackInfoRow';


const RecentlyPlayed = (props) => {
    const { playTrack } = props;
    const analytics = getAnalytics();

    const [trackInfos, setTrackInfos] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const refresh = () => {
        setTrackInfos(null);
        validTokenExists()
            .then(() => {
                axios
                    .post('/recently_played', {})
                    .then((res) => {
                        setTrackInfos(res.data.trackInfos);
                        setLastUpdated(res.data.lastUpdated);
                    })
                    .catch((err) => {
                        console.log(err);
                        setTrackInfos([]);
                        setLastUpdated(null);
                    });
            })
            .catch((e) => {
                // TODO
                console.log(e);
                setTrackInfos([]);
                setLastUpdated(null);
            });
    }

    useEffect(() => {
        refresh();
    }, []);

    return (
        <div>
            {trackInfos === null ? <Loading /> :
                <div>
                    <div>
                        <table style={{ width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '100%'}}>
                                        <div className='heading' style={{ paddingLeft: '20px' }}>
                                            Recently Played
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '150px', textAlign: 'right'}}>
                                        <button style={{ padding: '20px' }} onClick={refresh}>
                                            <Refresh style={{ fontSize: '24px' }} />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='thinRightDivider' />

                    {trackInfos.length !== 0 ? <></> :
                        <div style={{ padding: '20px', fontSize: '16px' }}>
                            Recently played episodes will show up here.
                        </div>
                    }

                    {trackInfos.map((trackInfo, index) => (
                        <div key={index}>
                            <TrackInfoRow
                                trackInfo={trackInfo}
                                playTrack={playTrack}
                                hideBottomBorder={index === trackInfos.length - 1}
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

RecentlyPlayed.propTypes = {

}

RecentlyPlayed.defaultProps = {

}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(RecentlyPlayed);
