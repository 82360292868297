import axios from 'axios';

import { useEffect, useRef, useState } from 'react'

// Redux.
import { connect } from 'react-redux';

import CloseFooter from './CloseFooter';
import Loading from './Loading'

import Drawer from '@mui/material/Drawer';

import SpeakerHeader from './SpeakerHeader';
import SpeakerTrack from './SpeakerTrack';


const EntityInfo = (props) => {
    const {
        missingSpeaker,
        speakerId,

        // Redux
        authenticated,
    } = props;

    const [info, setInfo] = useState(null);

    const [headerHeight, setHeaderHeight] = useState(0)

    const headerRef = useRef(null)

    useEffect(() => {
        axios
            .get(`/speaker/${encodeURIComponent(speakerId)}`)
            .then((res) => {
                setInfo(res.data);
            })
            .catch((err) => console.log(err));
    }, [speakerId]);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.clientHeight);
        }
    }, [info])

    const [openFullSummary, setOpenFullSummary] = useState(false);

    const toggleFullSummary = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!info || !info.summary) {
            return;
        }
        setOpenFullSummary(open);
    }

    const cleanSummary = (summary) => {
        return summary.replace(/ *\([^)]*\)/g, "");
    };

    return (
        <div>
            <Drawer anchor='bottom' open={openFullSummary} onClose={toggleFullSummary(false)}>
                {!info || !info.summary ? <></> :
                    <div className='horizontalCenter' style={{ height: '100vh' }}>
                        <div className='hasCloseFooter'>
                            <div style={{ padding: '20px' }}>
                                {cleanSummary(info.summary)}
                            </div>
                        </div>
                        <CloseFooter onClick={toggleFullSummary(false)} />
                    </div>
                }
            </Drawer>

            {!info ?
                <div>
                    <Loading overrideStyle={{ padding: '50px', textAlign: 'center' }} />
                </div>
                :
                !info.entity ?
                    !missingSpeaker ?
                        <div style={{ padding: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Unknown speaker
                        </div> :
                        missingSpeaker
                    :
                    <div>
                        <div className='fixedDrawerHeader blurBackground' ref={headerRef}>
                            <SpeakerHeader speaker={info.entity} clips={info.clips} />
                            <div className='thinDivider' />
                        </div>
                        <div className='entityInfoContent' style={{ paddingTop: `${headerHeight}px` }}>
                            {!info || !info.summary ?
                                <></>
                                :
                                <div>
                                    <div style={{ padding: '20px 20px 20px' }}>
                                        <button
                                            onClick={toggleFullSummary(true)}
                                            style={{ textAlign: 'left' }}>
                                            <div className='infoSummary'>
                                                {cleanSummary(info.summary)}
                                            </div>
                                            <div style={{ paddingTop: '6px', fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                                Wikipedia
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                !info || !info.tracks || info.tracks.length === 0 ?
                                    <></>
                                    :
                                    <div>
                                        <div className='entityInfoSectionTitle' style={{ padding: '20px 20px 0px' }}>
                                            Trending Episodes
                                        </div>
                                        {
                                            info.tracks.map((track, index) => (
                                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(234, 234, 234)' }}>
                                                    <SpeakerTrack speakerId={info.entity.id} track={track} authenticated={authenticated} />
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </div>

                    </div>
            }
        </div >
    );
}

EntityInfo.propTypes = {

}

EntityInfo.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EntityInfo);
