import { connect } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import {
    setCurrentClipsTopic,
    setNextClipsInfo,
} from '../redux/actions/dataActions';

import { getAnalytics, logEvent } from "firebase/analytics";
import Loading from '../components/Loading';
import axios from 'axios';
import TopicSpeakerClips from '../components/TopicSpeakerClips';
import useWindowDimensions from '../components/windowHelper';
import { loadTrack } from '../components/audioHelper';
import { PlayArrowRounded } from '@material-ui/icons';
import { ACCENT_COLOR } from '../constants/colors';
import { Search } from '@mui/icons-material';
import { LABEL_APPNAME, LABEL_TAGLINE } from '../constants/labels';

const Topic = ({
    setCurrentClipsTopic,
    setNextClipsInfo,
}) => {
    const navigate = useNavigate();
    let params = useParams();
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'topic'
        });
    }, [analytics]);

    const [topic, setTopic] = useState(null);
    const [loading, setLoading] = useState(true);

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        document.getElementById('audio').pause();
        if (!!params.topicId) {
            setLoading(true);
            axios
                .get(`/topic/${params.topicId}`)
                .then((res) => {
                    setLoading(false);
                    const data = res.data;
                    setTopic(data);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            setLoading(false);
        }
    }, [params, setTopic, setLoading]);

    const playSuggestion = (suggestion, clipId) => () => {
        // suggestion.clips = shuffle(suggestion.clips);

        if (clipId) {
            const newClips = [];
            for (let i = 0; i < suggestion.clips.length; i++) {
                const clip = suggestion.clips[i];
                if (clip.id === clipId) {
                    newClips.push(clip);
                    for (let j = 0; j < suggestion.clips.length; j++) {
                        if (j !== i) {
                            newClips.push(suggestion.clips[j])
                        }
                    }
                    break;
                }
            }
            suggestion.clips = newClips;
        }
        loadTrack(suggestion.clips[0].mp3URL, 0);
        setCurrentClipsTopic(suggestion.id);
        setNextClipsInfo(suggestion.id, suggestion.clips);
        navigate('/clips');
    }

    const searchClick = () => {
        navigate('/search');
    };

    return (
        <div>
            <div className="container">
                <div className='animate__animated animate__fadeIn'>
                    {loading ?
                        <Loading />
                        :
                        <div style={{ padding: '20px', background: 'white' }}>
                            {!!topic ?
                                <div>
                                    <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                                        <a href='/'>
                                            <div>
                                                <img className='appName' src='/appname2.png' />
                                            </div>
                                            <div className='tagline'>
                                                {LABEL_TAGLINE}
                                            </div>
                                        </a>
                                    </div>
                                    <div style={{ paddingBottom: '90px' }}>
                                        <TopicSpeakerClips
                                            hideTopic={true}
                                            playTopic={playSuggestion}
                                            showAll={true}
                                            overrideSpeakersPerRow={width > 420 ? 4 : 3}
                                            topic={topic}
                                            width={Math.min(538, width) - 40}
                                        />
                                    </div>
                                    <div className='playClipsFooter'>
                                        <div className='horizontalCenter'>
                                            <div style={{ textAlign: 'center', margin: '20px' }}>
                                                <button style={{ background: 'black', color: 'white', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '10px', width: '100%', borderRadius: '40px' }} onClick={playSuggestion(topic)}>
                                                    <div style={{ fontWeight: '500', fontSize: '18px' }}>
                                                        <PlayArrowRounded style={{ fontSize: '30px', verticalAlign: 'middle' }} />
                                                        <span style={{ verticalAlign: 'middle', margin: '0px 16px 0px 10px' }}>
                                                            Podcast Previews
                                                        </span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='center' style={{ textAlign: 'left', width: Math.min(538, width) - 40 }}>
                                    <div className='heading'>{params.topicId}</div>
                                    <div className='subheading'>No podcast previews found</div>
                                    <div className='subheading' style={{ padding: '30px 0px' }}>We're adding more podcasts daily, please check back soon!</div>
                                    <div>
                                        <a style={{ color: ACCENT_COLOR, fontWeight: '500', fontSize: '16px' }} href='/search'>
                                            Search for something else
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    setCurrentClipsTopic,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(Topic);