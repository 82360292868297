// Redux.
import { connect } from 'react-redux';

import ProfilePicture from './ProfilePicture';
import CloseFooter from './CloseFooter';
import { useEffect, useState } from 'react';
import { ArrowCircleRight } from '@mui/icons-material';
import { report } from './eventHelper';
import { PeopleAlt } from '@material-ui/icons';

const EditSpeaker = ({
    // Redux
    track,

    close,
    messageId,
    speaker
}) => {

    const [suggestedSpeakers, setSuggestedSpeakers] = useState([]);
    const [newSpeakerName, setNewSpeakerName] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const newSuggestedSpeakers = [];
        for (let i = 0; i < track.speakers.length; i++) {
            const trackSpeaker = track.speakers[i];
            if (trackSpeaker.id !== speaker.id && trackSpeaker.id !== 'unknown') {
                newSuggestedSpeakers.push(trackSpeaker);
            }
        }
        setSuggestedSpeakers(newSuggestedSpeakers);
    }, [])

    useEffect(() => {
        let closeTimeout = null;
        if (success) {
            closeTimeout = setTimeout(() => {
                close();
            }, 3000);
        }
        // effect cleanup
        return () => {
            clearTimeout(closeTimeout);
        }
    }, [success])

    const submitNewSpeakerName = () => {
        const newSpeakerNameWithoutWhiteSpace = newSpeakerName.trim();
        if (newSpeakerNameWithoutWhiteSpace.length > 0) {
            reportSuggestedSpeaker('', newSpeakerNameWithoutWhiteSpace)
        }
        setSuccess(true)
    }

    const suggestedSpeakerClick = (suggestedSpeaker) => () => {
        setNewSpeakerName(suggestedSpeaker.name)
        reportSuggestedSpeaker(suggestedSpeaker.id, suggestedSpeaker.name)
        setSuccess(true)
    }

    const reportSuggestedSpeaker = (suggestedSpeakerId, suggestedSpeakerName) => {
        report(1, {
            messageId: messageId,
            trackId: track.fullTrackId ?? track.id,
            suggestedSpeakerId: suggestedSpeakerId,
            suggestedSpeakerName: suggestedSpeakerName,
        });
    }

    return (
        <div className='hideScrollbar preventSelect'>
            <div className="container hasCloseFooter" style={{ height: window.innerHeight }}>
                <div className='horizontalCenter' style={{ padding: '20px 20px 100px' }}>
                    {success ?
                        <div style={{ padding: '60px 0px', textAlign: 'center' }}>
                            <button onClick={close}>
                                <div style={{ fontSize: '60px', padding: '80px 40px 0px' }}>
                                    ✅
                                </div>

                                <div style={{ fontSize: '24px', padding: '8px', fontWeight: '600' }}>
                                    {newSpeakerName}</div>
                                <div style={{ fontSize: '18px' }}>
                                    Thanks for reporting!
                                    <br />We'll take a look.
                                </div>
                            </button>
                        </div>
                        :
                        <div>
                            <div>
                                <div style={{ fontSize: '18px', fontWeight: '600', padding: '10px 0px 10px' }}>
                                    Who is speaking?
                                </div>

                                <table style={{ border: '1px solid rgb(224, 224, 224)', borderRadius: '16px', padding: '2px', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <input className='newSpeakerName' onChange={(e) => { setNewSpeakerName(e.target.value) }} placeholder="speaker's first and last name..."></input>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <button onClick={submitNewSpeakerName} style={{ padding: '8px 8px 4px', display: newSpeakerName.length === 0 ? 'none' : 'block' }}>
                                                    <ArrowCircleRight style={{ fontSize: '30px' }} />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {suggestedSpeakers.length === 0 ? <></>
                                :
                                <div>
                                    <div style={{ fontSize: '18px', fontWeight: '600', margin: '40px 0px 10px' }}>
                                        Maybe?
                                    </div>
                                    {suggestedSpeakers.map((suggestedSpeaker, index) => (
                                        <button key={index} onClick={suggestedSpeakerClick(suggestedSpeaker)} style={{ padding: '8px 12px', borderRadius: '16px', marginBottom: '16px', textAlign: 'left', border: '1px solid rgb(224, 224, 224)', width: '100%' }}>
                                            <table>
                                                <tbody>
                                                    <tr style={{ verticalAlign: 'middle' }}>
                                                        <td style={{ width: '62px' }}>
                                                            <ProfilePicture
                                                                imageSrc={suggestedSpeaker.imageURL}
                                                                color='white'
                                                                name={suggestedSpeaker.name}
                                                                width='52px'
                                                                height='62px'
                                                                margin='6px 12px 0px -2px' />
                                                        </td>
                                                        <td>
                                                            <div className='speakerNameSmall'>
                                                                {suggestedSpeaker.name}
                                                            </div>
                                                            <div className='speakerDescription'>
                                                                {suggestedSpeaker.description}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </button>
                                    ))}

                                    {speaker.id !== 'unknown' ?
                                        <button onClick={suggestedSpeakerClick({ name: 'unknown', id: 'unknown' })} style={{ padding: '8px 12px', borderRadius: '16px', marginBottom: '16px', textAlign: 'left', border: '1px solid rgb(224, 224, 224)', width: '100%' }}>
                                            <table>
                                                <tbody>
                                                    <tr style={{ verticalAlign: 'middle' }}>
                                                        <td style={{ width: '62px' }}>
                                                            <ProfilePicture
                                                                imageSrc='https://upload.wikimedia.org/wikipedia/commons/b/bc/Unknown_person.jpg'
                                                                color='white'
                                                                name='unknown'
                                                                width='52px' />
                                                        </td>
                                                        <td>
                                                            <div className='speakerNameSmall'>
                                                                Unknown
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </button>
                                        :
                                        <></>
                                    }

                                    <button onClick={suggestedSpeakerClick({ name: 'multiple speakers', id: '' })} style={{ padding: '8px 12px', borderRadius: '16px', marginBottom: '16px', textAlign: 'left', border: '1px solid rgb(224, 224, 224)', width: '100%' }}>
                                        <table>
                                            <tbody>
                                                <tr style={{ verticalAlign: 'middle' }}>
                                                    <td style={{ width: '62px' }}>
                                                        {/* <ProfilePicture
                                                            imageSrc='https://upload.wikimedia.org/wikipedia/commons/b/bc/Unknown_person.jpg'
                                                            color='white'
                                                            name='unknown'
                                                            width='52px'
                                                            height='62px'
                                                            margin='6px 12px 0px -2px' /> */}
                                                        <PeopleAlt style={{ padding: '6px', fontSize: '48px', background: 'rgb(242, 242, 242)', borderRadius: '50%', color: 'rgb(187, 191, 194)' }} />
                                                    </td>
                                                    <td>
                                                        <div className='speakerNameSmall'>
                                                            Multiple Speakers
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    <CloseFooter onClick={close} />
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => ({
    track: state.data.track,
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(EditSpeaker);
