import { forwardRef, useState } from 'react';

// Redux.
import { connect } from 'react-redux';
import { setSubscribeStatus } from '../redux/actions/userActions';

import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import LoginButtonPopup from './LoginButtonPopup';
import ProfilePicture from './ProfilePicture';
import ButtonTipPopup from './ButtonTipPopup';

// MUI.
import { Modal } from '@mui/material';
import { TIP_SUBSCRIBED } from '../constants/tips';
import EntityInfoDrawer from './EntityInfoDrawer';


const LoginButtonPopupForwardRef = forwardRef((props, ref) => (
    <LoginButtonPopup {...props} />
));

const SubscribedPopupForwardRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const TrackOverviewSpeaker = (props) => {
    const {
        authenticated,
        setSubscribeStatus,
        subscribeIds,
        speaker,
        speakerColor,
        speakerDurationTime,
        speakerStart,
    } = props;

    const analytics = getAnalytics();

    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const [openLoginButtonPopup, setOpenLoginButtonPopup] = useState(false);

    const toggleLoginButtonPopup = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenLoginButtonPopup(open);
    }

    const [showSubscribedPopup, setShowSubscribedPopup] = useState(false);

    const setShowSubscribedPopupFn = (open) => () => {
        setShowSubscribedPopup(open);
    };

    const isSubscribed = () => {
        const subscribeIdSet = new Set(subscribeIds);
        return subscribeIdSet.has(speaker.id);
    }
    const currentSubscribeStatus = isSubscribed();

    const subscribeClick = () => {
        if (!authenticated) {
            setOpenLoginButtonPopup(true);
            return;
        }
        if (!currentSubscribeStatus && !localStorage.TIP_SUBSCRIBED) {
            localStorage.setItem(TIP_SUBSCRIBED, true)
            setShowSubscribedPopup(true);
        }
        logEvent(analytics, !currentSubscribeStatus ? 'subscribe_true' : 'subscribe_false');
        setSubscribeStatus(speaker.id, !currentSubscribeStatus);
    }

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPersonInfo(open);
    }

    return (
        <div>
            <EntityInfoDrawer
                    open={openPersonInfo}
                    onClose={togglePersonInfo(false)}
                    speakerId={speaker.id} />

            <Modal
                open={openLoginButtonPopup}
                onClose={toggleLoginButtonPopup(false)}
            >
                <LoginButtonPopupForwardRef close={toggleLoginButtonPopup(false)} />
            </Modal>

            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showSubscribedPopup}
                onClose={setShowSubscribedPopupFn(false)}
            >
                <SubscribedPopupForwardRef close={setShowSubscribedPopupFn(false)}
                    content={(
                        <div>
                            <div>
                                <img src='/subscribe_fill.png' style={{ width: '80px' }} />
                            </div>
                            <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                                Subscribed
                            </div>
                            <div style={{ marginTop: '6px', fontSize: '18px', fontWeight: '400' }}>
                                {speaker.name}
                            </div>
                        </div>)} />
            </Modal>

            <table>
                <tbody>
                    <tr style={{ verticalAlign: 'middle' }}>
                        <td style={{ minWidth: '56px', textAlign: 'left', verticalAlign: 'middle' }}>
                            <button onClick={subscribeClick}>
                                <div className={currentSubscribeStatus ? 'heartBeat' : ''}>
                                    <img src={currentSubscribeStatus ? '/subscribe_fill.png' : '/subscribe_outline.png'} style={{ marginTop: '6px', marginRight: '30px', width: '40px' }} />
                                </div>
                            </button>
                        </td>

                        <td style={{ minWidth: '66px' }}>
                            <div style={{ paddingTop: '8px' }}>
                                <ProfilePicture
                                    imageSrc={speaker.imageURL}
                                    color={speakerColor}
                                    name={speaker.name}
                                    profileClick={togglePersonInfo(true)}
                                    width='55px'
                                    height='66px'
                                    margin='0px'
                                />
                            </div>

                        </td>

                        <td style={{ width: '100%' }}>
                            <button onClick={togglePersonInfo(true)} style={{ textAlign: 'left' }}>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                    <div className='speakerNameSmall'>
                                        {speaker.name}
                                    </div>
                                </div>
                            </button>
                            {!speakerDurationTime ?
                                <div className='speakerDescription'>
                                    {speaker.description}
                                </div>
                                :
                                <div className='speakerDescription'>
                                    {speakerDurationTime}
                                </div>
                            }
                            {!speakerStart ? <></>
                                :
                                speakerStart
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

TrackOverviewSpeaker.propTypes = {

}

TrackOverviewSpeaker.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    subscribeIds: state.user.subscribeIds,
})

const mapActionsToProps = {
    setSubscribeStatus,
}

export default connect(mapStateToProps, mapActionsToProps)(TrackOverviewSpeaker);
