import { connect } from 'react-redux';

const MyTest = ({
}) => {

    return (
        <div className='horizontalCenter'>
            my test
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(MyTest);