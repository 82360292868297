export const loadTrack = (mp3URL, setTime, norefresh) => {
    const audio = document.getElementById('audio');
    if (!norefresh) {
        audio.pause();
        audio.src = '';
    }
    if (audio.src !== mp3URL) {
        audio.src = mp3URL;
        audio.currentTime = setTime;
        if (localStorage.TIP_PLAY_FIRST_CLIP) {
            audio.play().catch(function () {
                // Browser doesn't allow play until user click.
            });
        }

        if (setTime !== undefined) {
            audio.addEventListener('loadeddata', () => {
                audio.currentTime = setTime;
                if (localStorage.TIP_PLAY_FIRST_CLIP) {
                    audio.play().catch(function () {
                        // Browser doesn't allow play until user click.
                    });
                }
            }, { once: true});
        }
    }
}