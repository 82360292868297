import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from "@mui/material/TextField";
import { useState, useEffect } from 'react'
import { setSearchText } from '../redux/actions/dataActions';
import Highlighter from 'react-highlight-words';
import {
    speakerName,
    speakerImageSrc,
    speakerDescription,
} from './speakerHelper'
import { fancyTimeFormatWithHMS } from "./timeHelper"
import { speakerColor } from "../constants/colors"
import { Clear, FastForward, FastRewind, PlayArrowRounded, Search } from '@material-ui/icons';
import { IconButton } from "@material-ui/core"
import { getAnalytics, logEvent } from "firebase/analytics";
import { isSingular, plural } from 'pluralize';
import ProfilePicture from './ProfilePicture';
import CloseFooter from './CloseFooter';
import { Input } from '@mui/material';

const SearchTranscript = ({
    close,
    curTime,
    onMessageTimeClick,
    searchText,
    setSearchText,
    track,
}) => {
    const analytics = getAnalytics();
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'search_transcript'
        });
    }, []);

    useEffect(() => {
        setSearchResults(search(searchText));
    }, [searchText])

    const search = (text) => {
        if (text === '') {
            return [];
        }
        let lowerCaseKeywords = new Set();
        // for (const [_, searchSuggestionGroup] of Object.entries(track.searchSuggestions)) {
        //     let matchFound = false;
        //     for (let j = 0; j < searchSuggestionGroup.length; j++) {
        //         const keyword = searchSuggestionGroup[j];
        //         if (text.toLowerCase() === keyword.toLowerCase()) {
        //             matchFound = true;
        //             break;
        //         }
        //     }
        //     if (matchFound) {
        //         lowerCaseKeywords = new Set(searchSuggestionGroup);
        //         break;
        //     }
        // }

        if (lowerCaseKeywords.size === 0) {
            const lowercaseText = text.toLowerCase();
            lowerCaseKeywords.add(lowercaseText);
        }

        const allKeywords = new Set();
        for (const lowercaseText of lowerCaseKeywords) {
            allKeywords.add(lowercaseText)
            if (lowercaseText.length > 2) {
                if (isSingular(lowercaseText)) {
                    allKeywords.add(plural(lowercaseText));
                }
            }
        }
        let keywordsArray = Array.from(allKeywords);
        const topicResults = [];
        for (let i = 0; i < track.transcript.length; i++) {
            const transcriptMessage = track.transcript[i];
            const transcriptMessageText = getTranscriptMessageText(transcriptMessage.message).toLowerCase();
            const matchingKeywords = keywordsArray.filter(keyword => { return transcriptMessageText.includes(keyword.toLowerCase()) });
            if (matchingKeywords.length > 0) {
                topicResults.push({
                    messageIndex: i,
                    searchWords: matchingKeywords,
                });
            }
        }

        return topicResults;
    }

    const onClick = (timeSeconds) => () => {
        onMessageTimeClick(timeSeconds + 0.01);
    }

    const inputHandler = (e) => {
        setSearchText(e.target.value);
    };

    const onTopicClick = (topicName) => () => {
        setSearchText(topicName);
    }

    const getTranscriptMessageText = (transcriptMessage) => {
        const transcriptMessageText = [];
        for (let wordIndex = 0; wordIndex < transcriptMessage.length; wordIndex++) {
            transcriptMessageText.push(transcriptMessage[wordIndex].text)
        }
        return transcriptMessageText.join('');
    }

    const getSearchableTopics = (track) => {
        const allTopicCounts = {};
        for (let i = 0; i < track.sectionInfos.length; i++) {
            const sectionInfo = track.sectionInfos[i];
            for (let topicIndex = 0; topicIndex < sectionInfo.topics.length; topicIndex++) {
                const topicLowerCase = sectionInfo.topics[topicIndex].toLowerCase();
                if (!(topicLowerCase in allTopicCounts)) {
                    allTopicCounts[topicLowerCase] = 0;
                }
                allTopicCounts[topicLowerCase] += 1;
            }
        }
        const foundTopics = new Set();
        for (let i = 0; i < track.transcript.length; i++) {
            const message = track.transcript[i];
            const lowerCaseMessageText = getTranscriptMessageText(message.message).toLowerCase();
            for (const lowerCaseTopic in allTopicCounts) {
                if (lowerCaseMessageText.includes(lowerCaseTopic)) {
                    foundTopics.add(lowerCaseTopic);
                }
            }
        }

        const sortedTopics = Array.from(foundTopics).sort((a, b) => { return allTopicCounts[b] - allTopicCounts[a] });
        return sortedTopics.slice(0, 13);
    }

    const topics = (
        <div>
            {!track ? <></> :
                getSearchableTopics(track).map((topic, index) => (
                    <button style={{ textAlign: 'left', width: '100%', padding: '8px', fontSize: '18px' }} key={index} onClick={onTopicClick(topic)}>
                        {topic}
                    </button>
                ))
            }
        </div>
    )

    const seekInfo = (diffSeconds) => {
        const audio = document.getElementById('audio');
        const absDiffSeconds = fancyTimeFormatWithHMS(Math.abs(diffSeconds) / audio.playbackRate);
        const color = 'rgb(80, 80, 80)';
        return (
            <div>
                {diffSeconds < 0 ?
                    <FastForward style={{ fontSize: '20px', marginRight: '4px', marginTop: '5px', color: color }} /> :
                    <FastRewind style={{ fontSize: '20px', marginRight: '4px', marginTop: '5px', color: color }} />
                }
                <span style={{ fontSize: '12px', color: color, verticalAlign: 'top', display: 'inline-block', marginTop: '7px' }}>{absDiffSeconds}</span>
            </div>
        )

    }

    const results = (
        <div>
            {
                searchResults.map((result) => (
                    <div
                        key={result.messageIndex}
                        style={{
                            padding: '20px',
                            border: `1px solid ${speakerColor(track.transcript[result.messageIndex].speakerId, track.speakers)}`,
                            backgroundColor: `${speakerColor(track.transcript[result.messageIndex].speakerId, track.speakers)}35`,
                            borderRadius: '8px',
                            marginBottom: '10px',
                            fontSize: '16px',
                        }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ minWidth: '66px' }}>
                                        <ProfilePicture
                                            imageSrc={speakerImageSrc(track.transcript[result.messageIndex].speakerId, track.speakers)}
                                            color={speakerColor(track.transcript[result.messageIndex].speakerId, track.speakers)}
                                            name={speakerName(track.transcript[result.messageIndex].speakerId, track.speakers)}
                                            profileClick={null}
                                            width='55px'
                                            height='66px'
                                            margin='0px 11px 0px 0px' />
                                    </td>
                                    <td>
                                        <div>
                                            {speakerName(track.transcript[result.messageIndex].speakerId, track.speakers)}
                                        </div>
                                        <div className='speakerDescription'>
                                            {speakerDescription(track.transcript[result.messageIndex].speakerId, track.speakers)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ margin: '10px 0px' }}>
                            <button
                                style={{ textAlign: 'left' }}
                                onClick={onClick(track.transcript[result.messageIndex].timeSeconds)}>
                                <div className='message'>
                                    <Highlighter
                                        highlightClassName="ais-Highlight-highlighted"
                                        unhighlightClassName="ais-Highlight-nonHighlighted"
                                        textToHighlight={getTranscriptMessageText(track.transcript[result.messageIndex].message)}
                                        searchWords={result.searchWords} />
                                </div>
                            </button>

                        </div>
                        <div style={{ paddingTop: '8px' }}>
                            <button
                                onClick={onClick(track.transcript[result.messageIndex].timeSeconds)}
                                style={{ padding: '0px 16px 0px 10px', border: `1px solid rgb(180, 180, 180)`, borderRadius: '20px', width: '100%', textAlign: 'center' }}>
                                {/* <PlayArrowRounded style={{ fontSize: '20px', marginRight: '4px', marginTop: '5px', color: ACCENT_COLOR }} />
                                <span style={{ fontSize: '12px', color: ACCENT_COLOR, verticalAlign: 'top', display: 'inline-block', marginTop: '7px' }}>{fancyTimeFormat(track.transcript[result.messageIndex].timeSeconds)}</span> */}
                                {
                                    seekInfo(curTime - track.transcript[result.messageIndex].timeSeconds)
                                }
                            </button>
                        </div>
                    </div>
                ))
            }
        </div >
    )

    return (
        <div className='hasCloseFooter' style={{ width: '100%' }}>
            <div id='searchTranscript'>
                <Input
                    onChange={inputHandler}
                    // variant="filled"
                    value={searchText}
                    disableUnderline={true}
                    fullWidth
                    placeholder="Search transcript"
                    startAdornment={
                        <Search style={{ fontSize: '18px', color: 'rgb(160, 160, 160)', marginLeft: '18px', marginRight: '10px' }} />
                    }
                    endAdornment={
                        searchText === '' ? <></> :
                            <IconButton style={{ marginRight: '12px' }} onClick={onTopicClick('')}>
                                <Clear style={{ fontSize: '18px', color: 'rgb(160, 160, 160)' }} />
                            </IconButton>
                    }
                    classes={{
                        root: 'searchTranscriptRoot',
                        input: 'searchTranscriptInput',
                    }}
                />
            </div>
            <div style={{ padding: '88px 20px 20px' }}>
                {searchText.trim().length === 0 ? topics : results}
            </div>
            <CloseFooter onClick={close} />
        </div>
    );
}

SearchTranscript.defaultProps = {

}

SearchTranscript.propTypes = {

}

const mapStateToProps = (state) => ({
    track: state.data.track,
    searchText: state.data.searchText
})

const mapActionsToProps = {
    setSearchText: setSearchText
}

export default connect(mapStateToProps, mapActionsToProps)(SearchTranscript);