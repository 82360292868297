import {
    LOAD_TRACK,
    LOAD_SUGGESTED_TOPICS,
    SET_CURRENT_CLIPS_TOPIC,
    SET_NEXT_CLIPS_INFO,
    SET_RELATED_TOPIC_CLIPS,
    SET_SEARCH_TEXT,
    SET_TRACK,
    SET_SUGGESTED_TOPICS,
    SHUFFLE_SUGGESTED_TOPICS,
} from '../types';

import axios from 'axios';

export const setRelatedTopicClips = (relatedTopicClips) => (dispatch) => {
    dispatch({
        type: SET_RELATED_TOPIC_CLIPS,
        payload: relatedTopicClips.slice(0, 30),
    });
}


export const clearTrack = () => (dispatch) => {
    dispatch({ type: LOAD_TRACK });
}


export const setCurrentClipsTopic = (topic) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_CLIPS_TOPIC,
        payload: topic,
    });
}


export const setNextClipsInfo = (topic, clipsInfo) => (dispatch) => {
    if (topic && clipsInfo) {
        dispatch({
            type: SET_NEXT_CLIPS_INFO,
            payload: {
                topic: topic,
                clipsInfo: clipsInfo.slice(0, 30)
            },
        });
    } else {
        dispatch({
            type: SET_NEXT_CLIPS_INFO,
            payload: null,
        });
        dispatch({
            type: SET_CURRENT_CLIPS_TOPIC,
            payload: null,
        });
    }
}

// TODO unused delete?
export const fetchNextClipsInfo = (trackId, messageIndex) => (dispatch) => {
    const nextClipsInfoUrl = messageIndex === null ? `/next/${trackId}` : `/next/${trackId}/${messageIndex}`;
    axios
        .get(nextClipsInfoUrl)
        .then((res) => {
            const clipsInfo = res.data;
            dispatch({
                type: SET_NEXT_CLIPS_INFO,
                payload: {
                    topic: null,
                    clipsInfo: clipsInfo,
                },
            });
        })
        .catch((err) => {
            console.log(err);
            // dispatch({
            //   type: SET_ERRORS,
            //   payload: err.response.data
            // });
        });
}


export const fetchTrack = (trackId, navigate) => (dispatch) => {
    dispatch({ type: LOAD_TRACK });
    axios
        .get(`/track/${trackId}`)
        .then((res) => {
            setTrack(res.data)(dispatch)
            // dispatch({
            //     type: SET_TRACK,
            //     payload: res.data
            // });
        })
        .catch((err) => {
            console.log(err);
            navigate('/missing')
            // dispatch({
            //   type: SET_ERRORS,
            //   payload: err.response.data
            // });
        });
}


export const fetchClip = (trackId, messageIndex) => (dispatch) => {
    dispatch({ type: LOAD_TRACK });
    axios
        .get(`/clip/${trackId}/${messageIndex}`)
        .then((res) => {
            setTrack(res.data)(dispatch)
            // dispatch({
            //     type: SET_TRACK,
            //     payload: res.data
            // });
        })
        .catch((err) => {
            console.log(err);
            // dispatch({
            //   type: SET_ERRORS,
            //   payload: err.response.data
            // });
        });
}

export const setTrack = (track) => (dispatch) => {
    dispatch({
        type: SET_TRACK,
        payload: track,
    });
}


export const fetchSuggestedTopics = (excludeSuggestions) => (dispatch) => {
    dispatch({ type: LOAD_SUGGESTED_TOPICS });
    axios
        .post('/suggested_topics', {
            excludeSuggestions: excludeSuggestions,
        })
        .then((res) => {
            dispatch({
                type: SET_SUGGESTED_TOPICS,
                payload: res.data.data
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_SUGGESTED_TOPICS,
                payload: []
            });
        });
}


export const shuffleSuggestedTopics = () => (dispatch) => {
    dispatch({ type: SHUFFLE_SUGGESTED_TOPICS });
}


export const setSearchText = (text) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_TEXT,
        payload: text,
    });
}
